<template>
  <LoadingButton
      v-bind="$props"
      :indefinite="true"
      :icon-only="iconOnly"
      :reversed="!reversed">
    <template #icon>
      <slot name="icon">
        <DefaultActionIcon />
      </slot>
    </template>
    <template #icon-loader>
      <slot name="icon-loader" />
    </template>
    <template #sections>
      <slot name="sections" />
    </template>
    <slot />
  </LoadingButton>
</template>
<script>
import DefaultActionIcon from '@carbon/icons-vue/es/circle-dash/24.js';
import IconButton from './IconButton.vue';
import LoadingButton from './LoadingButton.vue';

export default {
  components: {
    LoadingButton,
    DefaultActionIcon,
  },
  props: {
    ...IconButton.props,
    type: {
      type: [String, Array],
      default: () => 'action',
    },
  },
};
</script>

<template>
  <div
      class="icon-badge"
      :class="{[`icon-badge--${type}`]: true}">
    <div class="icon-badge__content">
      <slot />
    </div>

    <div
        v-if="!!$slots.icon"
        class="icon-badge__badge">
      <slot name="icon" />
    </div>
  </div>
</template>
<script>

export default {
  props: {
    type: {
      type: String,
      default: () => 'primary',
    },
    inline: {
      type: Boolean,
      default: () => false,
    },
  },
};
</script>
<style>

:root {
  --badge-color: currentColor;
  --badge-background-color: transparent;
}

.icon-badge {
  position: relative;
  display: grid;

  &__badge {
    position: absolute;

    top: calc(-1 * var(--dimension-medium) / 3);
    right: calc(-1 * var(--dimension-medium) / 3);
    display: flex;
    justify-content: center;
    align-items: center;

    width: var(--dimension-medium);
    height: var(--dimension-medium);

    color: var(--badge-color);

    border-radius: 50%;

    font-size: .5rem;

    &:empty {
      display: none;
    }

    svg * {
      fill: currentColor;
    }
  }

  &__content {
    display: grid;
  }

  &--inline {
    display: inline-flex;
    gap: var(--dimension-x-small);
    align-items: center;
  }

  &--inline &__badge {
    position: static;
  }

  &__content {
    flex: 1;
  }

  &--error &__badge {
    --badge-color: var(--color-white);
  }

  &--info &__badge {
    --badge-color: var(--color-charcoal);
  }
}

</style>

<template>
  <InputField
      v-bind="$attrs"
      :id="id"
      :name="name"
      :value="dateValue"
      :min="minValue"
      :max="maxValue"
      :read-only="!enabled"
      type="date"
      class="date-input"
      :class="{'date-input--value': !!modelValue}"
      @input="handleInput($event.target.value)" />
</template>

<script>
import { parse } from 'date-fns';
import {
  computed,
  ref,
  toRefs,
} from 'vue';
import { formatISO } from '../../../utils/date.js';
import InputField from './Input.vue';

export default {
  components: { InputField },
  inheritAttrs: false,
  props: {
    ...InputField.props,
    modelValue: {
      type: Date,
      default: () => null,
    },
    min: {
      type: Date,
      default: () => null,
    },
    max: {
      type: Date,
      default: () => null,
    },
    enabled: {
      type: Boolean,
      default: () => true,
    },
    formats: {
      type: Array,
      default: () => [
        'yyyy-MM-dd',
        'dd-MM-yyyy',
      ],
    },
  },
  emits: ['update:model-value'],
  setup(props, { emit }) {
    const {
      modelValue, validator, readOnly, min, max, formats,
    } = toRefs(props);
    const error = ref(false);
    return {
      error,
      dateValue: computed(() => formatISO(modelValue.value) || ''),
      classes: computed(() => ({
        'date-input--error': error.value || validator.value?.$error,
        'date-input--read-only': readOnly.value,
      })),
      maxValue: computed(() => formatISO(max.value) || ''),
      minValue: computed(() => formatISO(min.value) || ''),
      handleInput(value) {
        // First we need to check if we have a year, since parse is okay with 2-digit
        // years, and it screws up the parsing.
        if (value.split('-').filter((p) => p.length === 4).length === 0) {
          error.value = true;
          emit('update:model-value', null);
        } else {
          // Parse all the formats
          const values = formats.value.map(
            (f) => parse(value, f, new Date()),
          ).filter((v) => !Number.isNaN(v.getTime()));
          error.value = values.length === 0;
          // Pick values[0] if we have it, that's the most "important" one.
          emit('update:model-value', (error.value) ? null : values[0]);
        }
      },
    };
  },
};
</script>
<style>
.date-input {
  padding: 0 var(--dimension-small);

  color: rgba(var(--rgb-default-foreground), .25);
  font-variant-numeric: tabular-nums;

  &--value, &:focus-within {
    color: inherit;
  }
}
</style>

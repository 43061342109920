<template>
  <div
      class="websocket-connection"
      :class="{'websocket-connection--dot': receiving && open}">
    <SignalIcon
        v-if="open"
        :title="t('connectionOpen')" />
    <SignalOffIcon
        v-else
        :title="t('connectionClosed')"
        style="color: var(--color-error)" />
  </div>
</template>
<i18n>
{
  "nl": {
    "connectionOpen": "Scherm wordt bijgewerkt",
    "connectionClosed": "Geen verbinding"
  },
  "en": {
    "connectionOpen": "Screen is being updated",
    "connectionClosed": "No connection"
  }
}
</i18n>
<script>
import SignalIcon from '@carbon/icons-vue/es/connection-signal/16.js';
import SignalOffIcon from '@carbon/icons-vue/es/connection-signal--off/16.js';
import {
  inject,
  ref,
  watch,
} from 'vue';
import { useI18n } from 'vue-i18n';

export default {
  components: {
    SignalIcon,
    SignalOffIcon,
  },
  setup() {
    // refs
    const client = inject('websocketClient');
    const open = ref(false);
    const receiving = ref(false);
    let receiveTimer = null;

    // uses
    const { t } = useI18n();

    const receiveHandler = () => {
      if (!open.value) {
        open.value = true;
      }
      clearTimeout(receiveTimer);
      if (!receiving.value) {
        receiving.value = true;
      }
      receiveTimer = setTimeout(() => {
        receiving.value = false;
      }, 400);
    };

    const handleWatch = (nc) => {
      // Apparently, urql closes all the listeners on the old one?
      if (nc) {
        nc.on('closed', () => {
          open.value = false;
        });
        nc.on('opened', () => {
          open.value = true;
        });
        nc.on('pong', receiveHandler);
        nc.on('message', receiveHandler);
      }
    };

    watch(client, handleWatch);

    handleWatch(client.value, null);

    return {
      // refs
      open,
      receiving,

      // uses
      t,
    };
  },
};
</script>
<style>
.websocket-connection {
  display: inline-flex;
  gap: var(--dimension-xx-small);
  flex-direction: column;
  align-items: center;

  cursor: help;

  path:last-of-type {
    transform-origin: 50% 40%;
  }

  &--dot path:last-of-type {
    animation-name: websocket-receiving;
    animation-duration: 500ms;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }

}

@keyframes websocket-receiving {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(.48);
  }
}
</style>

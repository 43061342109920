<template>
  <div
      class="duration-input"
      :class="{'duration-input--error': validator?.$invalid}">
    <DateInput
        v-model="start"
        :enabled="modifiableStart"
        :validator="validator?.start"
        :required="!!validator?.start?.required"
        :min="min"
        :max="startMax"
        v-bind="$attrs" />

    <DateInput
        v-model="end"
        :enabled="modifiableEnd"
        :min="endMin"
        :max="max"
        :required="!!validator?.end?.required"
        :validator="validator?.end"
        v-bind="$attrs" />
  </div>
</template>

<script>
import { addDays } from 'date-fns';
import {
  computed,
  toRefs,
} from 'vue';
import DateInput from './DateInput.vue';
import { toModelValueRefs } from '../../../utils/refs.js';

export default {
  components: { DateInput },
  inheritAttrs: false,
  props: {
    modelValue: {
      type: Object,
      default: () => null,
    },
    validator: {
      type: Object,
      default: () => null,
    },
    modifiableStart: {
      type: Boolean,
      default: () => true,
    },
    modifiableEnd: {
      type: Boolean,
      default: () => true,
    },
    requiredStart: {
      type: Boolean,
      default: () => true,
    },
    requiredEnd: {
      type: Boolean,
      default: () => true,
    },
    min: {
      type: Date,
      default: () => null,
    },
    max: {
      type: Date,
      default: () => null,
    },
  },
  emits: ['update:model-value'],

  setup(props, { emit }) {
    const { modelValue } = toRefs(props);
    const { start, end } = toModelValueRefs(modelValue, emit, ['start', 'end']);
    return {
      start,
      end,
      startMax: computed(() => addDays(modelValue.value?.end || new Date(), -1)),
      endMin: computed(() => addDays(modelValue.value?.start || new Date(), 1)),
    };
  },
};
</script>

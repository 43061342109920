<template>
  <div
      class="duration-block"
      :class="{
        'duration-block--past': days.every(d => isPast(d)),
        'duration-block--today': days.some(d => isToday(d)),
        'duration-block--multi': multi,
        'duration-block--inline': inline,
        'duration-block--multi-month': multi && days.length > 2 && months.length > 1,
      }">
    <div
        v-for="(day, index) in days"
        :key="day.getTime()"
        :style="{'grid-column': index+ 1}"
        :class="{
          'duration-block__day--first': index === 0,
          'duration-block__day--last': index === days.length - 1,
          'duration-block__day--single': days.length === 1,
          'duration-block__day--past': isPast(day),
          'duration-block__day--today': isToday(day),
          'duration-block__day--multi': multi && !showCompleteRange,
        }"
        class="duration-block__day">
        <span
            v-if="days.length > 1 || inline"
            class="duration-block__weekday">
          {{ shortWeekDay(day) }}
        </span>
      <span
          v-else
          class="duration-block__weekday">
          {{ weekDay(day) }}
        </span>
      <span class="duration-block__day-of-month">
          {{ dayOfMonth(day) }}
        </span>
    </div>

    <template v-if="showMonth">
      <div
          v-for="m in months"
          :key="m.month.getTime()"
          :style="{'grid-column': `${m.start} / ${m.end}`}"
          class="duration-block__month">
        {{ (months.length > 1 || otherYear) ? shortMonth(m.month) : month(m.month) }}
        <template v-if="otherYear">
          {{ year(m.month) }}
        </template>
      </div>
    </template>

    <template v-if="loading">
      <div class="duration-block__day">
      <span
          class="duration-block__weekday">
        DDD
        </span>
        <span class="duration-block__day-of-month">
          00
        </span>
      </div>
      <div
          v-if="showMonth"
          class="duration-block__month">
        MMM
      </div>
    </template>
  </div>
</template>
<script>

import {
  computed,
  toRefs,
} from 'vue';
import {
  addYears,
  getMonth,
  isEqual,
  isPast,
  isToday,
  startOfMonth,
  startOfYear,
} from 'date-fns';
import { useI18n } from 'vue-i18n';
import {
  areRangesOverlapping,
  eachDayOfRange,
} from '../../utils/date.js';

export default {
  props: {
    duration: {
      type: Object,
      required: false,
      default: () => null,
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    showMonth: {
      type: Boolean,
      default: () => true,
    },
    inline: {
      type: Boolean,
      default: () => false,
    },
    showCompleteRange: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props) {
    const { duration, loading, showCompleteRange } = toRefs(props);
    const { locale } = useI18n();
    const shortWeekDayFormat = computed(
      () => new Intl.DateTimeFormat(locale.value, {
        weekday: 'short',
      }),
    );
    const weekDayFormat = computed(() => new Intl.DateTimeFormat(locale.value, {
      weekday: 'long',
    }));
    const dayOfMonthFormat = computed(
      () => new Intl.DateTimeFormat(locale.value, {
        day: '2-digit',
      }),
    );
    const monthFormat = computed(
      () => new Intl.DateTimeFormat(locale.value, {
        month: 'long',
      }),
    );
    const shortMonthFormat = computed(
      () => new Intl.DateTimeFormat(locale.value, {
        month: 'short',
      }),
    );
    const yearFormat = computed(
      () => new Intl.DateTimeFormat(locale.value, {
        year: 'numeric',
      }),
    );

    const days = computed(() => {
      if (loading.value || !duration.value) {
        return [];
      }
      const allDays = eachDayOfRange(duration.value);
      if (showCompleteRange.value) {
        return allDays;
      }
      return allDays.filter(
        (_, idx) => idx === 0 || idx === allDays.length - 1,
      );
    });

    return {
      days,
      multi: computed(() => eachDayOfRange(duration.value).length > 2),
      // Convert days into a {month, start-column, end-column} datastructure
      months: computed(() => days.value.reduce((months, d) => {
        const m = startOfMonth(d);
        const last = (months.length) ? months.pop() : [null, null];
        if (last[0] && !isEqual(last[0], m)) {
          months.push(last);
        }
        months.push([m, (last[0] && isEqual(last[0], m)) ? last[1] + 1 : 1]);
        // this will give {month, day-count}
        return months;
      }, []).map(([month, count]) => ({ month, count })).reduce((months, m) => {
        const last = months.pop();
        if (last) {
          months.push(last);
          months.push({
            ...m,
            start: last.end,
            end: last.end + m.count,
          });
        } else {
          months.push({
            ...m,
            start: 1,
            end: m.count + 1,
          });
        }
        // this will give {month, count, start, end}
        return months;
      }, [])),
      sameMonth: computed(() => days.value.length
          && days.value.every(
            (d) => getMonth(d) === getMonth(days.value[0]),
          )),
      otherYear: computed(() => !areRangesOverlapping(duration.value, {
        start: startOfYear(new Date()),
        end: addYears(startOfYear(new Date()), 1),
      })),
      shortWeekDay: (d) => shortWeekDayFormat.value.format(d),
      weekDay: (d) => weekDayFormat.value.format(d),
      dayOfMonth: (d) => dayOfMonthFormat.value.format(d),
      month: (d) => monthFormat.value.format(d),
      shortMonth: (d) => shortMonthFormat.value.format(d),
      year: (d) => yearFormat.value.format(d),
      isPast,
      isToday,
    };
  },
};
</script>
<style>
:root {
  --duration-block-color: var(--color-background);
  --duration-block-background: var(--color-primary-background);
  --duration-block-complement-color: var(--color-primary-complement-foreground);
  --duration-block-complement-background: var(--color-primary-complement-background);
}

.duration-block {
  display: grid;
  grid-auto-columns: 1fr;
  grid-gap: 0 var(--dimension-hair-line);
  grid-template-rows: 1fr auto;
  align-self: flex-start;

  min-width: 5.25rem;

  color: var(--duration-block-complement-color);
  background-color: var(--duration-block-complement-background);

  &--inline {
    display: inline-grid;
  }

  &--past {
    color: var(--color-secondary-complement-foreground);
    background-color: var(--color-secondary-complement-background);
  }

  &--today {
    color: var(--color-secondary-complement-foreground);
    background-color: var(--color-secondary-complement-background);
  }

  &__month {
    justify-self: center;

    padding: var(--dimension-x-small);

    font-size: .75rem;
    font-weight: 400;
    text-transform: uppercase;

  }

  &--multi-month &__month {
    justify-self: flex-start;
  }

  &__day {
    display: grid;
    grid-auto-flow: row;

    grid-gap: var(--dimension-hair-line);
    grid-row: 1;

    padding: var(--dimension-x-small);

    color: var(--duration-block-color);
    background-color: var(--duration-block-background);
    justify-items: center;

    &--past {
      --duration-block-color: var(--color-secondary-foreground);
      --duration-block-background: var(--color-secondary-background);
      --duration-block-complement-color: var(--color-secondary-complement-foreground);
      --duration-block-complement-background: var(--color-secondary-complement-background);
    }

    &--today {
      --duration-block-color: var(--color-secondary-complement-foreground);
      --duration-block-background: var(--color-secondary-complement-background);
      --duration-block-complement-color: var(--color-secondary-complement-foreground);
      --duration-block-complement-background: var(--color-secondary-complement-background);
    }
  }

  &--inline &__day {
    grid-auto-flow: column;
    grid-gap: var(--dimension-x-small);
    align-items: center;
  }

  &__weekday {
    font-size: .75rem;
    font-weight: 400;
    text-transform: uppercase;
  }

}
</style>

<template>
  <DialogActionPane :scrollable="false">
    <TableContainer
        class="crud-table">
      <TableContent
          :rows="modelValue">
        <template
            v-if="$slots.header && modelValue?.length > 0"
            #header>
          <slot name="header" />
        </template>
        <template #row="{row, index}">
          <InteractiveTableRow
              class="crud-table__row"
              :enabled="interactive"
              @click="$emit('click', row)">
            <slot
                name="row"
                :index="index"
                :row="row" />
            <TableCell class="crud-table__remove-cell">
              <button
                  v-if="canDelete"
                  type="button"
                  tabindex="0"
                  class="crud-table__remove"
                  :title="(confirm) ? t('remove') : t('removeSubmit')"
                  @click.stop.prevent="(confirm) ? handleConfirm(row) : remove(row)">
                <DeleteIcon />
                <span>{{ (confirm) ? t('remove') : t('removeSubmit') }}</span>
              </button>
            </TableCell>
          </InteractiveTableRow>
        </template>
        <template #empty>
          <slot name="empty" />
        </template>
      </TableContent>
    </TableContainer>
    <template
        v-if="!!$slots.actions"
        #actions>
      <slot name="actions" />
    </template>
    <template
        v-if="!!$slots.buttons || !!$slots.add"
        #buttons>
      <slot name="buttons" />
      <slot
          name="add"
          :add="add" />
    </template>
    <teleport to="#modal">
      <ModalContainer
          :open="confirmItem !== null"
          @update:open="confirmItem = null">
        <DialogPanel
            type="warning"
            :title="t('remove')"
            @close="confirmItem=null">
          <Paragraph>
            <slot
                name="removeWarning"
                :row="confirmItem">
              {{ t('removeWarning') }}
            </slot>
          </Paragraph>
          <template #ok>
            <IconButton
                type="destructive"
                :loading="removeLoading"
                @click="remove(confirmItem)">
              <template #icon>
                <DeleteIcon />
              </template>
              {{ t('removeSubmit') }}
            </IconButton>
          </template>
        </DialogPanel>
      </ModalContainer>
    </teleport>
  </DialogActionPane>
</template>
<i18n>
{
  "nl": {
    "removeSubmit": "Verwijder",
    "removeWarning": "Weet je zeker dat je dit wilt verwijderen?",
    "remove": "Verwijderen"
  },
  "en": {
    "removeSubmit": "Remove",
    "removeWarning": "Are you sure you want to remove this?",
    "remove": "Remove"
  }
}
</i18n>
<script>
import {
  ref,
  toRefs,
} from 'vue';
import DeleteIcon from '@carbon/icons-vue/es/close/20.js';
import { useI18n } from 'vue-i18n';
import TableContainer from './TableContainer.vue';
import InteractiveTableRow from './InteractiveTableRow.vue';
import TableContent from './TableContent.vue';
import DialogActionPane from '../panels/DialogActionPane.vue';
import TableCell from './TableCell.vue';
import ModalContainer from '../modal/ModalContainer.vue';
import DialogPanel from '../modal/DialogPanel.vue';
import IconButton from '../buttons/IconButton.vue';
import Paragraph from '../typography/Paragraph.vue';

export default {
  components: {
    Paragraph,
    IconButton,
    DialogPanel,
    ModalContainer,
    DeleteIcon,
    TableCell,
    DialogActionPane,
    TableContent,
    TableContainer,
    InteractiveTableRow,
  },
  props: {
    modelValue: {
      type: Array,
      required: true,
    },
    interactive: {
      type: Boolean,
      default: () => false,
    },
    canDelete: {
      type: Boolean,
      default: () => true,
    },
    confirm: {
      type: Boolean,
      default: () => false,
    },
    idField: {
      type: String,
      default: () => 'id',
    },
  },
  emits: ['update:model-value', 'click'],
  setup(props, { emit }) {
    const confirmItem = ref(null);
    const removeLoading = ref(false);

    const { t } = useI18n();
    const { modelValue, idField } = toRefs(props);
    return {
      confirmItem,
      removeLoading,

      t,

      handleConfirm(item) {
        confirmItem.value = item;
      },

      async add(item) {
        if (item) {
          const idx = (idField.value)
            ? modelValue.value.map((i) => i[idField.value]).indexOf(item[idField.value])
            : modelValue.value.indexOf(item);
          if (idx === -1) {
            emit('update:model-value', [...modelValue.value, item]);
          }
        }
      },
      async remove(item) {
        removeLoading.value = true;
        const idx = (idField.value)
          ? modelValue.value.map((i) => i[idField.value]).indexOf(item[idField.value])
          : modelValue.value.indexOf(item);
        if (idx > -1 && idField.value) {
          emit('update:model-value', modelValue.value.filter((i) => item[idField.value] !== i[idField.value]));
        } else if (idx > -1 && !idField.value) {
          emit('update:model-value', modelValue.value.filter((i) => item !== i));
        }
        removeLoading.value = false;
        confirmItem.value = null;
      },
    };
  },
};
</script>
<style>
.crud-table {
  &__remove {
    @media (any-hover: hover) {
      &:hover {
        color: var(--color-error);
        opacity: .25;
      }
    }
    color: inherit;
    background-color: transparent;
    border: none;
    opacity: 1;
    outline: none;
    cursor: pointer;

    transition: opacity var(--animation-default-duration) ease-in-out, color var(--animation-default-duration) linear;

    appearance: none;

    &:focus-within {
      opacity: .5;
    }

    > svg {
      display: inline-block;

      vertical-align: middle;
    }

    > span {
      display: none;
    }
  }

  &__remove-cell {
    width: 2rem;
  }

  &__empty {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: var(--dimension-small);

    font-style: italic;

    user-select: none;
  }

  &__row {
    @media (any-hover: hover) {
      &:has(.crud-table__remove:hover) {
        background-color: rgba(var(--rgb-error), .1);
      }
    }
  }
}
</style>

<template>
  <LoaderPane :loading="loading">
    <ListInput
        v-bind="$attrs"
        :model-value="modelValue"
        value-field="id"
        label-field="name"
        :placeholder="t('selectCountry')"
        :options="countries"
        :required="required"
        @update:model-value="v => $emit('update:model-value', v)" />
  </LoaderPane>
</template>
<i18n>
{
  "nl": {
    "selectCountry": "Selecteer een land"
  },
  "en": {
    "selectCountry": "Select a country"
  }
}
</i18n>
<script>
import gql from 'graphql-tag';
import { useQuery } from '@urql/vue';
import { useI18n } from 'vue-i18n';
import {
  computed,
  toRefs,
} from 'vue';
import LoaderPane from '../loading/LoaderPane.vue';
import ListInput from '../forms/ListInput.vue';

const COUNTRIES_QUERY = gql`
query Countries($language: ID) {
  countries(language: $language) {
    id
    name
  }
}`;

export default {
  components: {
    LoaderPane,
    ListInput,
  },
  props: {
    ...ListInput.props,
    defaultCountries: {
      type: Array,
      default: () => ['NL', 'BE', 'DE'],
    },
    filtered: {
      type: Array,
      default: () => [],
    },
  },
  emits: ListInput.emits,
  setup(props) {
    // refs
    const { defaultCountries, filtered } = toRefs(props);

    // uses
    const { t, locale } = useI18n();

    // query
    const query = useQuery({
      query: COUNTRIES_QUERY,
      variables: computed(() => ({ language: locale.value })),
    });

    // computed
    const countries = computed(() => ((query.fetching.value)
      ? []
      : defaultCountries.value
        .map((dc) => query.data.value?.countries.filter((c) => c.id === dc)[0])
        .concat(query.data.value?.countries.filter(
          (c) => defaultCountries.value.indexOf(c.id) === -1 && filtered.value.indexOf(c.id) === -1,
        ))));

    return {
      t,

      loading: query.fetching,
      countries,
    };
  },
};
</script>

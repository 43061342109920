<template>
  <ListItem
      class="interactive-list-item"
      :class="{'interactive-list-item--enabled': enabled}"
      @click="(enabled) ? $emit('click') : undefined">
    <slot />
  </ListItem>
</template>
<script>
import ListItem from './ListItem.vue';

export default {
  components: { ListItem },
  props: {
    enabled: {
      type: Boolean,
      default: () => true,
    },
  },
  emits: ['click'],
};
</script>
<style>

.interactive-list-item {
  @media (any-hover: hover) {
    &--enabled:hover {
      background-position: 0 100%;
    }
  }

  background-color: transparent;
  background-image: linear-gradient(
      to bottom,
      var(--list-item-background) 0%,
      var(--list-item-background) 50%,
      color-mix(in srgb, var(--list-item-alternate-background), var(--color-default-background)) 50%,
      color-mix(in srgb, var(--list-item-alternate-background), var(--color-default-background)) 100%
  );
  background-position: 0 0;
  background-size: 100% 200%;

  transition: background-position var(--animation-default-duration) ease-in-out, color var(--animation-default-duration) ease-in-out, transform var(--animation-default-duration) ease-in-out;

  &--enabled {
    cursor: pointer;
  }

  &:nth-child(2n + 1) {
    background-image: linear-gradient(
        to bottom,
        var(--list-item-alternate-background) 0%,
        var(--list-item-alternate-background) 50%,
        color-mix(in srgb, var(--list-item-alternate-background), var(--color-default-background)) 50%,
        color-mix(in srgb, var(--list-item-alternate-background), var(--color-default-background)) 100%
    );
  }
}

</style>

<template>
  <li class="list-item">
    <slot />
  </li>
</template>
<style>
.list-item {
  --list-item-background: var(--color-default-background);
  --list-item-alternate-background: var(--color-background);

  display: flex;

  min-width: 0;

  background-color: var(--list-item-background);
  &:nth-child(2n+1) {
    background-color: var(--list-item-alternate-background);
  }
}
</style>

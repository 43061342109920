<script>
import {
  h,
  toRefs,
} from 'vue';

export default {
  props: {
    tag: {
      type: String,
      default: () => 'label',
    },
    selectable: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props, { slots }) {
    const { tag, selectable } = toRefs(props);
    return () => h(
      tag.value,
      {
        class: (selectable.value) ? ['text-label', 'text-label--selectable'] : ['text-label'],
      },
      slots.default(),
    );
  },
};
</script>
<style>

/*noinspection CssUnusedSymbol*/
.text-label {
  cursor: inherit;

  font-family: var(--font-sans-serif);
  font-size: .75rem;
  font-weight: normal;
  line-height: 120%;

  &--selectable {
    user-select: text;
  }
}
</style>

<template>
  <Card v-bind="$props">
    <template #title>
      <slot name="title">
        <CardTitle>
          {{ license.combination.friendlyName }}
        </CardTitle>
      </slot>
    </template>

    <template #actions>
      <slot name="actions" />
    </template>

    <SportTag :sport="license.sport">
      {{ gt(`sport.${license.sport}`) }}
    </SportTag>
    <Tag v-if="license.licenseType !== 'STANDARD'">
      {{ gt(`licenseType.${license.licenseType}`) }}
    </Tag>
  </Card>
</template>
<i18n>
{
  "nl": {
  },
  "en": {
  }
}
</i18n>
<script>
import { useI18n } from 'vue-i18n';
import Card from '../card/Card.vue';
import Tag from '../tag/Tag.vue';
import SportTag from '../tag/SportTag.vue';
import CardTitle from '../card/CardTitle.vue';

export default {
  components: {
    CardTitle,
    SportTag,
    Tag,
    Card,
  },
  props: {
    ...Card.props,
    license: {
      type: Object,
      default: () => null,
    },
  },
  setup() {
    const { t } = useI18n();
    const { t: gt } = useI18n({ useScope: 'global' });
    return {
      t,
      gt,
    };
  },
};
</script>

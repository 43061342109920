<template>
  <PagerPage
      :id="id"
      class="flow-step">
    <!-- Title in FlowPanel navigation bar -->
    <slot
        v-if="false"
        name="title" />
    <!-- Icon in FlowPanel navigation bar -->
    <slot
        v-if="false"
        name="icon" />

    <!-- Custom next/previous displayed in FlowPanel's PagerPane -->
    <slot
        v-if="false"
        :previous="previous"
        name="previous" />
    <slot
        v-if="false"
        :next="next"
        name="next" />

    <!-- Main content, only displayed if enabled -->
    <slot
        v-if="enabled"
        :next="next"
        :previous="previous" />
  </PagerPage>
</template>
<script>
import {
  inject,
  onMounted,
  onUnmounted,
  onUpdated,
  ref,
  toRefs,
} from 'vue';
import PagerPage from './PagerPage.vue';
import { FLOW_PANEL_CONTROLLER_KEY } from './FlowPanel.vue';

export default {
  components: { PagerPage },
  props: {
    id: {
      type: String,
      default: () => '',
      required: true,
    },
    enabled: {
      type: Boolean,
      default: () => true,
    },
    visible: {
      type: Boolean,
      default: () => true,
    },
    completed: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props, { slots }) {
    const controller = inject(FLOW_PANEL_CONTROLLER_KEY);
    const {
      id, visible, enabled, completed,
    } = toRefs(props);

    if (!controller) {
      throw new Error('No FlowPanel found');
    }

    const stepRef = {
      id,
      visible,
      enabled,
      completed,
      previous: slots.previous,
      next: slots.next,
      title: ref(slots.title),
      icon: ref(slots.icon),
    };

    onMounted(() => {
      controller.register(stepRef);
    });
    onUnmounted(() => {
      controller.unregister(stepRef);
    });

    onUpdated(() => {
      // We just update the custom slots we have, since the main body is reactive in the DOM
      stepRef.title.value = slots.title;
      stepRef.icon.value = slots.icon;
    });

    return {
      next() {
        return controller.next();
      },
      previous() {
        return controller.previous();
      },
    };
  },
};
</script>

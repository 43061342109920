<template>
  <div
      class="scrollable-pane"
      :class="{
        'scrollable-pane--contain': contain,
        'scrollable-pane--disabled-on-mobile': disableOnMobile,
        'scrollable-pane--align-start': alignStart,
        [`scrollable-pane--${direction}`]: true
      }">
    <div class="scrollable-pane__wrapper">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    contain: {
      type: Boolean,
      default: () => true,
    },
    alignStart: {
      type: Boolean,
      default: () => true,
    },
    direction: {
      type: String,
      default: () => 'vertical',
    },
    disableOnMobile: {
      type: Boolean,
      default: () => false,
    },
  },
};
</script>
<style>
.scrollable-pane {
  display: flex;
  flex: 1;

  &--vertical {
    flex-direction: column;

    overflow-y: auto;
  }

  &--horizontal {
    flex-direction: row;

    overflow-x: auto;
  }

  &--contain {
    overscroll-behavior: contain;
  }

  &__wrapper {
    display: flex;
    flex: 1 1 0;

    min-width: auto;
    min-height: auto;
    justify-items: flex-start;
  }

  &--align-start &__wrapper {
    align-content: flex-start;
  }

  &--vertical &__wrapper {
    flex-direction: column;
  }

  &--horizontal &__wrapper {
    flex-direction: row;
  }

}

@media (max-width: 450px) {
  .scrollable-pane {
    &--disabled-on-mobile {
      flex: none;

      overflow-x: inherit;
      overflow-y: inherit;
    }
    &--disabled-on-mobile &__wrapper {
      flex-shrink: 0;
    }
  }
}
</style>

<template>
  <Pane
      class="inset-pane"
      :class="classes">
    <slot />
  </Pane>
</template>
<script>
import {
  computed,
  toRefs,
} from 'vue';
import Pane from './Pane.vue';

export default {
  components: { Pane },
  props: {
    type: {
      type: String,
      default: () => null,
    },
  },
  setup(props) {
    const { type } = toRefs(props);
    return {
      classes: computed(() => ((type.value) ? [`inset-pane--${type.value}`] : [])),
    };
  },
};
</script>
<style>
.inset-pane {
  gap: var(--dimension-medium-large);

  padding: var(--dimension-medium);

  &--small {
    gap: var(--dimension-small);

    padding: var(--dimension-small);
  }

  &--extra-small {
    gap: var(--dimension-x-small);

    padding: var(--dimension-x-small);
  }

  &--none {
    padding: 0;
  }
}
</style>

<template>
  <footer class="action-footer">
    <div
        v-if="$slots.actions"
        class="action-footer__actions">
      <slot name="actions" />
    </div>
    <div
        v-if="$slots.buttons"
        class="action-footer__buttons">
      <slot name="buttons" />
    </div>
  </footer>
</template>
<script setup></script>
<style>
.action-footer {
  display: flex;
  gap: var(--dimension-small);
  flex-direction: row;

  &__buttons, &__actions {
    display: flex;
    gap: var(--dimension-small);
    flex-direction: row;
    align-self: center;
  }

  &__buttons {
    flex: 1;
    justify-content: flex-end;
  }
}
</style>

<template>
  <li>
    <router-link
        v-slot="{ navigate, href, isActive, isExactActive }"
        :to="to"
        :active-class="activeClass"
        :exact-active-class="exactActiveClass"
        :replace="replace"
        custom>
      <a
          class="segmented-tab"
          :class="{
          'segmented-tab--active': isActive,
          'segmented-tab--exact': isExactActive,
          'segmented-tab--disabled': !enabled,
        }"
          :href="href"
          @click="navigate">
        <slot name="icon" />
        <slot>
      <span class="segmented-tab__label">
        <slot name="label" />
      </span>
        </slot>
      </a>
    </router-link>
  </li>
</template>
<script>
import { RouterLink } from 'vue-router';

export default {
  props: {
    ...RouterLink.props,
    enabled: {
      type: Boolean,
      default: () => true,
    },
  },
};
</script>
<style>
.segmented-tab {
  @media (any-hover: hover) {
    &:hover {
      background-color: rgba(var(--rgb-default-background), .25);
    }
  }
  display: block;

  padding: var(--dimension-x-small) 0;

  color: inherit;
  border-bottom: var(--dimension-thick-line) solid rgba(var(--rgb-primary-background), .15);

  text-decoration: none;

  transition: border-bottom-color var(--animation-default-duration) linear;

  &__label {
    display: block;

    padding: var(--dimension-small) 0;
  }

  &--disabled {
    opacity: .25;

    pointer-events: none;
  }

  &--exact {
    border-bottom-color: var(--color-primary-background);

    font-weight: 500;
  }
}
</style>

<template>
  <ValidatorForm
      :name="name"
      :validator="validator"
      :submit="submit"
      :external-results="externalResults"
      @update:external-results="v => $emit('update:external-results', v)">
    <template #default="{success, loading, validator:v, failures}">
      <ActionPane class="test-validator-form__action">
        <BasicPane type="large">
          <FormMessagePane
              :validator="v"
              :failures="failures" />

          <slot
              :failures="failures"
              :loading="loading"
              :success="success"
              :validator="v" />
        </BasicPane>

        <template #actions>
          <slot
              name="actions"
              :failures="failures"
              :loading="loading"
              :success="success"
              :validator="v" />
        </template>

        <template #buttons>
          <FormSubmitButton
              :loading="loading"
              :success="success"
              :validator="v">
            <slot
                name="submit-label"
                :failures="failures"
                :loading="loading"
                :success="success"
                :validator="v" />
          </FormSubmitButton>
        </template>
      </ActionPane>
    </template>
  </ValidatorForm>
</template>
<script>
import ActionPane from '../panels/ActionPane.vue';
import FormMessagePane from './FormMessagePane.vue';
import ValidatorForm from './ValidatorForm.vue';
import FormSubmitButton from './FormSubmitButton.vue';
import BasicPane from '../panels/BasicPane.vue';

export default {
  components: {
    BasicPane,
    FormSubmitButton,
    ValidatorForm,
    FormMessagePane,
    ActionPane,
  },
  props: {
    ...ValidatorForm.props,
  },
  emits: ['update:external-results'],
};
</script>

<template>
  <Card
      v-if="!production && !demo"
      class="todo-list"
      :title="title">
    <template #title>
      <slot name="title"/>
    </template>
    <ul>
      <li
          v-for="(item, idx) in items"
          :key="idx">
        <Field>
          <template #default="{id, fieldName}">
            <CheckBox
                :id="id"
                :label="item"
                :name="fieldName" />
          </template>
        </Field>
      </li>
    </ul>
  </Card>
</template>
<script>
import Card from '../card/Card.vue';
import Field from '../fields/FormField.vue';
import CheckBox from '../widgets/forms/CheckBox.vue';
import useSession from '../../session/session.js';

export default {
  components: {
    CheckBox,
    Field,
    Card,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: () => 'TODO',
    },
    improvement: {
      type: Boolean,
      default: () => false,
    },
  },
  setup() {
    const { flags } = useSession();
    return {
      flags,
      production: import.meta.env.PROD,
      demo: import.meta.env.VITE_DEMO === 'true',
    };
  },
};
</script>
<style>
.todo-list {
  --card-color: var(--color-yellow);
  align-self: start;
  justify-self: start;

  margin: var(--dimension-small); /* Normally not allowed on a block, but this is dev */
}
</style>

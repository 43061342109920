<template>
  <label
      class="switch-box"
      :class="{
        'switch-box--error': validator && validator.$error,
        'switch-box--read-only': readOnly,
        'switch-box--disabled': disabled,
    }">
    <input
        :id="id"
        v-bind="$attrs"
        v-model="internalValue"
        type="checkbox"
        class="switch-box__input" >
    <slot
        v-if="!!$slots.content || !!$slots.default || label"
        name="content"
        :for="id">
      <TextLabel
          tag="span"
          class="switch-box__label"
          :for="id">
        <slot :label="label">
          {{ label }}
        </slot>
      </TextLabel>
    </slot>
  </label>
</template>

<script>
import {
  computed,
  toRefs,
} from 'vue';
import Input from './Input.vue';
import TextLabel from '../../labels/TextLabel.vue';

export default {
  components: { TextLabel },
  inheritAttrs: false,
  props: {
    ...Input.props,
    modelValue: {
      type: Boolean,
      default: () => false,
    },
    id: {
      type: String,
      default: () => '',
    },
    name: {
      type: String,
      default: () => '',
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    label: {
      type: String,
      default: () => '',
    },
  },
  emits: ['update:modelValue', 'check', 'uncheck'],
  setup(props, { emit }) {
    const { modelValue } = toRefs(props);
    return {
      internalValue: computed({
        get() {
          return modelValue.value;
        },
        set: (v) => {
          emit('update:modelValue', !!v);
        },
      }),
    };
  },
};
</script>

<style>
.switch-box {
  @media (any-hover: hover) {
    &:hover &__input {
      padding: var(--dimension-xx-small) calc(var(--dimension-medium) - var(--dimension-xx-small)) var(--dimension-xx-small) calc(2*var(--dimension-xx-small));
    }
    &:hover &__input:checked {
      padding: var(--dimension-xx-small) calc(2*var(--dimension-xx-small)) var(--dimension-xx-small) calc(var(--dimension-medium) - var(--dimension-xx-small));
    }
  }
  display: grid;
  grid-template-columns: min-content 1fr;
  gap: var(--dimension-small);
  align-items: center;

  cursor: pointer;

  &--disabled {
    opacity: .5;
    cursor: auto;
  }

  &--read-only {
    cursor: auto;

    pointer-events: none;
  }

  &__label {
    padding: var(--dimension-x-small) 0;

    color: var(--color-default-foreground);
  }

  &__input {
    position: relative;

    width: 2.6rem;
    height: 1.70rem;

    margin: 0;

    padding: var(--dimension-xx-small) var(--dimension-medium) var(--dimension-xx-small) var(--dimension-xx-small);

    color: var(--color-default-foreground);

    background-color: rgba(var(--rgb-foreground), .1);

    border: inherit;
    border-radius: var(--dimension-medium);
    outline: none;

    cursor: pointer;

    font-family: var(--font-sans-serif);
    font-size: 1rem;
    font-weight: 400;
    line-height: 2rem;

    vertical-align: middle;

    transition: padding-left ease-in-out var(--animation-default-duration), padding-right ease-in-out var(--animation-default-duration);

    appearance: none;

    &--error {
      border-color: var(--color-red);
    }

    &--read-only {
      opacity: .5;

      pointer-events: none;
    }

    &--disabled &__input {
      cursor: auto;
    }

    &::after {
      position: relative;
      display: block;
      content: "✅";

      width: var(--dimension-medium-large);
      height: var(--dimension-medium-large);

      color: transparent;
      background-color: color-mix(in srgb, var(--color-primary-background), var(--color-background) 50%);

      border: var(--dimension-hair-line) solid color-mix(in srgb, var(--color-primary-background), var(--color-background) 30%);
      border-radius: var(--dimension-medium);
    }

    &::before {
      position: absolute;
      top: .25rem;
      right: var(--dimension-x-small);
      bottom: .25rem;
      left: var(--dimension-x-small);
      display: block;
      content: "✅";

      color: transparent;

      background-color: transparent;
      background-image: linear-gradient(to right,
        var(--color-primary-background) 0%,
        var(--color-primary-background) 50%,
        var(--color-background) 50%,
        var(--color-background) 100%
      );
      border-radius: var(--dimension-medium);
    }

    &:checked {
      padding: var(--dimension-xx-small) var(--dimension-xx-small) var(--dimension-xx-small) var(--dimension-medium);
    }

  }

}

</style>

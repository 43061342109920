<template>
  <Pane
      tag="section"
      class="inline-dialog-action-pane">
    <slot name="main">
      <slot />
    </slot>
    <footer
        v-if="!!$slots.actions || !!$slots.buttons"
        class="inline-dialog-action-pane__footer">
      <div
          v-if="$slots.actions"
          class="inline-dialog-action-pane__actions">
        <slot name="actions" />
      </div>
      <div
          v-if="$slots.buttons"
          class="inline-dialog-action-pane__buttons">
        <slot name="buttons" />
      </div>
    </footer>
  </Pane>
</template>
<script>

import Pane from './Pane.vue';

export default {
  components: { Pane },
  props: {
    scrollable: {
      type: Boolean,
      default: () => true,
    },
  },
};
</script>
<style>
.inline-dialog-action-pane {
  padding: var(--dimension-small);

  background-color: var(--color-background);

  &__footer {
    display: flex;
    flex-direction: row;
  }

  &__buttons, &__actions {
    display: flex;
    gap: var(--dimension-small);
    flex-direction: row
  }

  &__buttons {
    flex: 1;
    justify-content: flex-end;
  }

}
</style>

<template>
  <select
      class="select-input"
      @change="handleInput">
    <option
        v-if="!required"
        class="select-input__empty">
      <slot name="empty" />
    </option>
    <template
        v-for="option in actualOptions"
        :key="option && option[valueField]">
      <option
          v-if="option"
          :selected="(modelValue
        && (modelValue[valueField] === option[valueField])) ? 'selected' : undefined"
          :value="option[valueField]">
        <slot :option="option">
          {{ option[labelField] }}
        </slot>
      </option>
    </template>
  </select>
</template>
<script>

export default {
  props: {
    modelValue: {
      type: Object,
      default: () => null,
    },
    options: {
      type: [Array, Promise],
      default: () => [],
    },
    required: {
      type: Boolean,
      default: () => false,
    },
    valueField: {
      type: String,
      default: () => 'id',
    },
    labelField: {
      type: String,
      default: () => 'label',
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      resolved: false,
      actualOptions: [],
    };
  },
  watch: {
    options() {
      this.checkPromise();
    },
  },
  mounted() {
    this.checkPromise();
  },
  methods: {
    handleInput(ev) {
      this.$emit('update:modelValue', this.actualOptions.find(
          (opt) => `${opt[this.valueField]}` === ev.target.value,
      ));
    },
    async checkPromise() {
      if (this.options instanceof Promise) {
        this.actualOptions = [];
        this.resolved = false;
        this.actualOptions = await this.options;
        this.resolved = true;
      } else {
        this.resolved = true;
        this.actualOptions = this.options;
      }
    },
  },
};
</script>
<style>
.select-input {
  display: block;

  width: 100%;

  padding: var(--dimension-x-small) var(--dimension-medium-large) var(--dimension-x-small) var(--dimension-small);

  color: var(--color-input-text);

  background-color: transparent;

  background-image: url("@carbon/icons/svg/chevron--down.svg");
  background-repeat: no-repeat;
  background-position: right var(--dimension-small) center;
  background-size: .75rem .75rem;
  border: none;
  border-radius: 0;

  cursor: pointer;

  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;

  white-space: nowrap;
  text-overflow: ellipsis;

  appearance: none;

  &:focus {
    outline: none;
  }

  > option {
    color: var(--color-input-text);
  }

  &__empty {
    color: rgba(var(--rgb-default-foreground), .5);
  }

  &[disabled] {
    @media (any-hover: hover) {
      &:hover {
        background-position: center right .75em, 0 0;
      }
    }
    color: var(--color-black);
    background-position: center right .75em, 0 0;
    cursor: not-allowed;
  }

}
</style>

<template>
  <span
    v-if="duration"
    class="duration-span">
    <template v-if="isSame">
      {{ start }}
    </template>
    <template v-else-if="!duration.start && duration.end">
      {{ t('till') }} {{ end }}
    </template>
    <template v-else>
      {{ start }}&#8201;&rarr;&#8201;<template
        v-if="duration.end">{{ end }}</template>
    </template>
  </span>
</template>
<i18n>
{
  "nl": {
    "till": "t/m"
  },
  "en": {
    "till": "until"
  }
}
</i18n>
<script>
import {
  computed,
  toRefs,
} from 'vue';
import { useI18n } from 'vue-i18n';

export default {
  props: {
    duration: {
      type: Object,
      default: () => null,
    },
    dateStyle: {
      type: Object,
      default: () => ({ month: 'long', day: 'numeric', year: 'numeric' }),
    },
  },
  setup(props) {
    const { duration, dateStyle } = toRefs(props);
    const { locale, t } = useI18n();

    const hasTimeStart = computed(() => duration.value?.start.getMinutes() !== 0
        || duration.value?.start.getHours() !== 0);
    const hasTimeEnd = computed(() => duration.value?.end.getMinutes() !== 0
        || duration.value?.end.getHours() !== 0);

    const dateStyleStart = computed(() => ((hasTimeStart.value)
      ? dateStyle.value : { ...dateStyle.value, hour: undefined, minute: undefined }));
    const dateStyleEnd = computed(() => ((hasTimeEnd.value)
      ? dateStyle.value : { ...dateStyle.value, hour: undefined, minute: undefined }));

    const formatterStart = computed(() => new Intl.DateTimeFormat(
      locale.value,
      dateStyleStart.value,
    ));
    const formatterEnd = computed(() => new Intl.DateTimeFormat(
      locale.value,
      dateStyleEnd.value,
    ));
    const formatStart = (v) => formatterStart.value.format(v);
    const formatEnd = (v) => formatterEnd.value.format(v);

    const start = computed(() => ((duration.value?.start) ? formatStart(duration.value.start) : ''));
    const end = computed(() => ((duration.value?.end) ? formatEnd(duration.value.end) : ''));

    const isSame = computed(() => start?.value === end?.value);

    return {
      t, isSame, start, end,
    };
  },
};
</script>

<template>
  <div class="tag">
    <slot />
  </div>
</template>
<style>
:root {
  --tag-background: var(--color-primary-background);
  --tag-color: var(--color-default-background);
}
.tag {
  display: block;
  align-self: flex-start;

  padding: var(--dimension-x-small);

  color: var(--tag-color);
  background-color: var(--tag-background);
  border-radius: .125rem;

  font-family: var(--font-sans-serif);
  font-size: .6rem;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;

  justify-self: flex-start;
}
</style>

<template>
  <FieldSet>
    <Paragraph>
      {{ t('intro') }}
      <a
        target="_blank"
        rel="noopener noreferrer"
        :href="t('password.helpUrl')">
        {{ t('password.helpUrlText') }}
      </a>
    </Paragraph>
    <Field
      :label="t('password.label')"
      :validator="validator.password1"
      name="password">
      <template #default="{ id, fieldName }">
        <TextInput
          :id="id"
          v-model="validator.password1.$model"
          type="password"
          :name="fieldName"
          @update:model-value="$emit('update:modelValue', $event)" />
      </template>
      <template #error>
        <li v-if="validator.password1.required.$invalid">
          {{ t('password.required') }}
        </li>
        <li v-if="validator.password1.sameAs.$invalid">
          {{ t('password.sameAsOld') }}
        </li>
      </template>
    </Field>

    <Field
      :label="t('password.repeat')"
      :validator="validator.password2"
      name="password">
      <template #default="{ id, fieldName }">
        <TextInput
          :id="id"
          v-model="validator.password2.$model"
          type="password"
          :name="fieldName"
          @update:model-value="$emit('update:modelValue', $event)" />
      </template>
      <template #error>
        <li v-if="validator.password2.required.$invalid">
          {{ t('password.required') }}
        </li>
        <li v-if="!validator.password2.required.$invalid && validator.password2.same.$invalid">
          {{ t('password.notSame') }}
        </li>
      </template>
    </Field>
  </FieldSet>
</template>
<i18n>
{
  "nl": {
    "intro": "Hieronder kan je een wachtwoord invullen; herhaal het wachtwoord om te voorkomen dat er een typfoutje in zit.",
    "password": {
      "helpUrl": "https://www.consumentenbond.nl/internet-privacy/wachtwoord-onthouden",
      "helpUrlText": "Veilige wachtwoorden zijn uniek, onthoudbaar en lang",
      "label": "Wachtwoord",
      "labelCurrent": "Huidig wachtwoord",
      "labelNew": "Nieuw wachtwoord",
      "notSame": "Wachtwoorden zijn niet hetzelfde",
      "repeat": "Herhaal wachtwoord",
      "required": "Vul een wachtwoord in",
      "sameAsOld": "Je nieuwe wachtwoord is hetzelfde als je oude wachtwoord"
    }
  },
  "en": {
    "intro": "Fill in your password below; repeat the password to prevent typing errors.",
    "password": {
      "helpUrl": "https://www.consumentenbond.nl/internet-privacy/wachtwoord-onthouden",
      "helpUrlText": "Secure passwords are unique, memorable and long",
      "label": "Password",
      "labelCurrent": "Current password",
      "labelNew": "New password",
      "notSame": "Passwords are not the same",
      "repeat": "Repeat password",
      "required": "Enter a password",
      "sameAsOld": "Your password is the same as the old password"
    }
  }
}
</i18n>
<script>
import { useI18n } from 'vue-i18n';
import { useVuelidate } from '@vuelidate/core';
import {
  ref,
  toRefs,
  watch,
} from 'vue';
import {
  not,
  required,
  sameAs,
} from '@vuelidate/validators';
import FieldSet from './FieldSet.vue';
import Field from '../fields/FormField.vue';
import TextInput from '../widgets/forms/TextInput.vue';
import Paragraph from '../typography/Paragraph.vue';

export default {
  components: {
    Paragraph, FieldSet, TextInput, Field,
  },
  props: {
    modelValue: {
      type: String,
      default: () => null,
    },
    oldPassword: {
      type: String,
      default: () => null,
    },
  },
  emits: ['update:modelValue'],
  setup(props) {
    const { t } = useI18n();
    const { modelValue, oldPassword } = toRefs(props);

    const password1 = ref(modelValue.value || '');
    const password2 = ref(modelValue.value || '');
    const rules = {
      password1: {
        required,
        sameAs: not(sameAs(oldPassword)),
        $lazy: true,
      },
      password2: {
        required,
        same: sameAs(password1),
        $lazy: true,
      },
    };

    watch(modelValue, (mv) => {
      if (!mv && password1.value !== mv && password1.value === password2.value) {
        password1.value = mv.value;
        password2.value = mv.value;
      }
    });

    const validator = useVuelidate(rules, { password1, password2 });
    return {
      t,
      validator,
    };
  },
};
</script>

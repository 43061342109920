<template>
  <div
      class="vertical-pane">
    <slot />
  </div>
</template>
<style>
.vertical-pane {
  display: flex;

  gap: var(--dimension-small);
  flex-direction: column;
}
</style>

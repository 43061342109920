<script>
import {
  h,
  toRefs,
} from 'vue';

export default {
  props: {
    tag: {
      type: String,
      default: () => 'div',
    },
  },
  setup(props, { slots }) {
    const { tag } = toRefs(props);
    return () => h(
      tag.value,
      {
        class: 'pane',
      },
      slots.default(),
    );
  },
};
</script>
<style>

.pane {
  display: flex;
  flex-direction: column;

  min-width: 0;

  min-height: 0;

  &:only-child {
    flex: 1;
  }
}
</style>

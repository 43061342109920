<template>
  <div
      class="pager-pane">
    <div
        class="pager-pane__wrapper"
        :style="style">
      <slot
          :index="index"
          :next="next"
          :previous="previous" />
    </div>
  </div>
</template>
<script>
import {
  computed,
  onUpdated,
  ref,
  toRefs,
} from 'vue';

export default {
  props: {
    modelValue: {
      type: [String, null],
      default: () => null,
    },
    tabIds: {
      type: Array,
      default: () => null,
    },
  },
  emits: ['update:model-value'],
  setup(props, { slots, emit }) {
    const { modelValue, tabIds } = toRefs(props);

    const findIds = () => {
      if (tabIds.value) {
        return tabIds.value;
      }
      const s = slots.default(true);
      if (s.length === 1 && !s[0].props.id) {
        return s[0].children.map((c) => c.props?.id).filter((i) => !!i);
      }
      return s.filter((c) => c.props?.id).map((c) => c.props?.id);
    };

    const ids = ref(findIds());

    const index = computed(() => ((modelValue.value)
      ? ids.value.indexOf(modelValue.value)
      : null));

    const hasNext = computed(() => index.value + 1 < ids.value.length);
    const hasPrevious = computed(() => index.value > 0);

    const next = () => {
      const idx = Math.min(
        ids.value.length,
        index.value + 1,
      );
      emit('update:model-value', ids.value[idx]);
    };

    const previous = () => {
      const idx = Math.max(
        0,
        index.value - 1,
      );
      emit('update:model-value', ids.value[idx]);
    };

    const refresh = () => {
      ids.value = findIds();
    };

    onUpdated(() => {
      const newIds = findIds();
      if (newIds !== ids.value
          && (!newIds
              || !ids.value
              || newIds.toString() !== ids.value.toString())) {
        ids.value = newIds;
      }
    });

    return {
      index,
      style: computed(() => ({
        transform: `translate3d(${((index.value && index.value !== -1) ? index.value : 0) * -100}%,0,0)`,
      })),

      next,
      previous,
      refresh,

      hasNext,
      hasPrevious,
    };
  },
};
</script>
<style>
.pager-pane {
  display: flex;

  overflow-x: hidden;

  &__wrapper {
    display: flex;
    flex-direction: row;

    /* This forces the wrapper to not grow beyond 100%, forcing the children to behave */
    width: 100%;

    transition: transform var(--animation-default-duration);
  }
}
</style>

<template>
  <li
      class="tab-panel"
      :class="{
          'tab-panel--active': isActive,
          'tab-panel--exact': isActive,
        }"
      @click="$emit('update:model-value', id)">
    <slot name="icon" />
    <slot>
      <span class="tab-panel__label">
        <slot name="label" />
      </span>
    </slot>
    <slot
        v-if="false"
        name="content" />
  </li>
</template>
<script>

import {
  computed,
  inject,
  onMounted,
  toRefs,
} from 'vue';

export default {
  props: {
    id: {
      type: String,
      default: () => null,
      required: true,
    },
    modelValue: {
      type: String,
      default: () => null,
    },
  },
  emits: ['update:model-value'],
  setup(props, { slots }) {
    const { id, modelValue } = toRefs(props);
    const controller = inject('tabController');

    const register = () => {
      controller.register({
        id: id.value,
        slots,
      });
    };

    onMounted(register);

    return {
      isActive: computed(() => modelValue.value === id.value),
    };
  },
};
</script>
<style>
.tab-panel {
  @media (any-hover: hover) {
    &:hover {
      background-color: rgba(var(--rgb-default-background), .25);
    }
  }
  display: block;

  padding: var(--dimension-small) var(--dimension-small);

  color: inherit;
  background-color: #E2E2E2;
  background-color: color-mix(in srgb, var(--color-background), var(--color-default-foreground) 5%);
  border-left: 0 solid var(--color-primary-background);
  cursor: pointer;

  text-decoration: none;

  transition: border-left-width var(--animation-default-duration) linear, background-color var(--animation-default-duration);

  &__label {
    display: block;

    padding: var(--dimension-small) 0;
  }

  &--active {
    background-color: rgba(var(--rgb-default-background), .5);
  }

  &--exact {
    background-color: var(--color-default-background);
    border-left: var(--dimension-x-small) solid var(--color-primary-background);

    font-weight: 500;
  }
}
</style>

<template>
  <IconBadge class="admin-badge">
    <template #icon>
      <PermissionIcon
          :enabled="enabled"
          :allowed="allowed"
          :allowed-text="allowedText"
          :help-text="helpText" />
    </template>
    <slot
        :allowed="allowed"
        :enabled="enabled" />
  </IconBadge>
</template>
<i18n>
{
}
</i18n>
<script>
import { useI18n } from 'vue-i18n';
import IconBadge from './IconBadge.vue';
import PermissionIcon from '../users/PermissionIcon.vue';

export default {
  components: {
    PermissionIcon,
    IconBadge,
  },
  props: {
    ...PermissionIcon.props,
  },
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
};
</script>
<style>
.admin-badge {
  color: var(--color-default-foreground);
}
</style>

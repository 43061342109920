<template>
  <div
      class="definition-entry">
    <Header5
        tag="dt"
        class="definition-entry__title">
      <slot name="title" />
    </Header5>
    <TextLabel
        tag="dd"
        class="definition-entry__value">
      <slot />
    </TextLabel>
  </div>
</template>
<script>
import Header5 from '../typography/Header5.vue';
import TextLabel from '../labels/TextLabel.vue';

export default {
  components: { TextLabel, Header5 },
};
</script>
<style>
.definition-entry {
  display: grid;
  grid-gap: var(--dimension-x-small);
  grid-template-rows: min-content 1fr;
  break-inside: avoid;

  &__title {
    display: flex;
    flex-direction: row;

    color: var(--color-secondary-header);
  }

  &__value {
    user-select: text;
  }

  &__footer {
    margin: $dimension-small 0;

    &:empty {
      display: none;
    }
  }

}

</style>

<template>
  <Header3 class="section-panel-title">
    <slot />
  </Header3>
</template>
<script>
import Header3 from '../typography/Header3.vue';

export default {
  components: { Header3 },
};
</script>
<style>

.section-panel-title {
  color: var(--color-secondary-header);

  text-decoration: underline;
  text-decoration-color: var(--color-main-header-background);
  text-decoration-style: solid;
}
</style>

<template>
  <Pane class="sheet-action-pane">
    <Pane class="sheet-action-pane__main">
      <slot name="main">
        <ScrollablePane
            v-if="scrollable">
          <slot />
        </ScrollablePane>
        <slot v-else />
      </slot>
    </Pane>
    <slot name="footer">
      <footer
          v-if="!!$slots.actions"
          class="sheet-action-pane__actions">
        <slot name="actions" />
      </footer>
    </slot>
  </Pane>
</template>
<script>
import ScrollablePane from './ScrollablePane.vue';
import Pane from './Pane.vue';

export default {
  components: { ScrollablePane, Pane },
  props: {
    scrollable: {
      type: Boolean,
      default: () => true,
    },
  },
};
</script>
<style>
.sheet-action-pane {
  &__main {
    flex: 1;
  }

  &__actions {
    display: flex;
    gap: var(--dimension-small);
    flex-direction: column;
    align-self: stretch;

    padding: var(--dimension-small);
  }

}
</style>

<template>
  <FieldSet class="age-field-set">
    <template #title>
      <slot name="title" />
    </template>

    <Paragraph>
      {{ t('intro') }}
    </Paragraph>

    <Field
        :label="t('yearOfBirth.label')"
        :validator="validator.yearOfBirth"
        :size="15"
        name="yearOfBirth">
      <template #default="{ id, fieldName }">
        <NumberInput
            :id="id"
            v-model="validator.yearOfBirth.$model"
            :min="1900"
            :max="yearMax"
            :validator="validator.yearOfBirth"
            :name="fieldName" />
      </template>
      <template #error>
        <li v-if="validator.yearOfBirth.oldEnough.$invalid">
          {{ t('yearOfBirth.oldEnough') }}
        </li>
      </template>
    </Field>

    <transition name="animation-cross-fade">
      <Field
          v-if="isUnderAge"
          :validator="validator.underAgeConsent"
          :required="!!validator.underAgeConsent.required"
          name="underAge">
        <template #default="{ id, fieldName }">
          <CheckBox
              :id="id"
              v-model="validator.underAgeConsent.$model"
              :name="fieldName"
              :label="t('underAgeConsent.label')" />
        </template>
        <template #error>
          <li v-if="validator.underAgeConsent.required.$invalid">
            {{ t('underAgeConsent.required') }}
          </li>
        </template>
      </Field>
    </transition>
  </FieldSet>
</template>
<i18n>
{
  "nl": {
    "intro": "Je geboortejaar is niet verplicht, we gebruiken het alleen voor deelname aan wedstrijden of regelingen waar een leeftijdseis aan hangt.",
    "underAgeConsent": {
      "label": "Ik ben minderjarig en verklaar dat bij deze aanvraag of bij inschrijving van een wedstrijd, ik hiervoor toestemming heb van mijn ouder(s)/voogd(en).",
      "required": "Je ouder(s)/voogd(en) dienen akkoord te gaan"
    },
    "yearOfBirth": {
      "label": "Geboortejaar",
      "oldEnough": "Je bent nog geen 6",
      "required": "Vul een jaar in"
    }
  },
  "en": {
    "intro": "Your year of birth is not required, we only use it for shows and other arrangements with an age requirement",
    "underAgeConsent": {
      "label": "I am a minor and state that with this request and when I register for a show, I have my parents' or guardians' consent.",
      "required": "Your parents or guardians need to give their consent"
    },
    "yearOfBirth": {
      "label": "Birthdate",
      "oldEnough": "You are not yet 6",
      "required": "Enter a year"
    }
  }
}
</i18n>
<script>
import {
  computed,
  ref,
  toRefs,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useVuelidate } from '@vuelidate/core';
import {
  addYears,
  getYear,
} from 'date-fns';
import FieldSet from './FieldSet.vue';
import Field from '../fields/FormField.vue';
import CheckBox from '../widgets/forms/CheckBox.vue';
import { toModelValueRefs } from '../../utils/refs.js';
import NumberInput from '../widgets/forms/NumberInput.vue';
import Paragraph from '../typography/Paragraph.vue';

export default {
  components: {
    Paragraph,
    NumberInput,
    CheckBox,
    Field,
    FieldSet,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  emits: ['update:model-value'],
  setup(props, { emit }) {

    // refs
    const { modelValue } = toRefs(props);
    const {
      yearOfBirth,
    } = toModelValueRefs(
      modelValue,
      emit,
      ['yearOfBirth'],
    );
    const underAgeConsent = ref(null);

    // uses
    const { t } = useI18n();

    // computed
    const isUnderAge = computed(() => yearOfBirth.value
        && (getYear(new Date()) - yearOfBirth.value) < 18);

    // validator
    const validator = useVuelidate(
      {
        underAgeConsent: {
          required: (v) => !isUnderAge.value || !!v,
        },
        yearOfBirth: {
          oldEnough: (value) => !value || getYear(new Date()) - value >= 6,
        },
      },
      {
        underAgeConsent,
        yearOfBirth,
      },
    );

    return {
      // uses
      t,

      // computed
      isUnderAge,
      yearMax: getYear(addYears(new Date(), -6)),

      // validator
      validator,

    };
  },
};
</script>

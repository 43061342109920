<template>
  <Panel
      class="window-panel"
      :loading="loading"
      :title="title"
      tabindex="0"
      @click.stop
      @keydown.esc.prevent.stop="$emit('close')">
    <template #title>
      <slot name="title" />
    </template>

    <template #default>
      <slot />
    </template>

    <template #actions>
      <slot name="actions" />
      <PanelAction
          class="window-panel__close"
          @click="$emit('close')">
        <CloseIcon />
      </PanelAction>
    </template>
  </Panel>
</template>
<script>
import Close32 from '@carbon/icons-vue/es/close/32.js';
import Panel from '../panels/Panel.vue';
import PanelAction from '../panels/PanelAction.vue';

export default {
  components: {
    PanelAction,
    Panel,
    CloseIcon: Close32,
  },
  props: { ...Panel.props },
  emits: ['close'],
};
</script>
<style>
.window-panel {
  max-width: 95vw;
  max-height: var(--dimension-window-max-height);

  &__close {
    margin-right: calc(-1 * var(--dimension-small));
  }
}

@media screen and (max-width: 512px) {
  .window-panel {
    max-width: 100vw;
    max-height: 100dvh;

    &__close {
      position: absolute;
      top: var(--dimension-small);
      right: var(--dimension-small);
    }
  }
}
</style>

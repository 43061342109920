<template>
  <svg
      v-if="path"
      ref="map"
      class="map-pane"
      :viewBox="`0 0 ${width} ${height}`"
      :width="width"
      :height="height">

    <g class="map-pane__provinces">
      <path
          v-for="feature in features"
          :key="feature.id"
          class="map-pane__province"
          :class="{'map-pane__province--current': province && feature.properties.statnaam === province}"
          :d="path(feature)" />
      <circle
          v-if="projected"
          v-bind="pointProps"
          class="map-pane__point" />
    </g>
  </svg>
</template>
<script>
import { feature } from 'topojson-client';
import {
  geoMercator,
  geoPath,
} from 'd3-geo';
import {
  computed,
  toRefs,
} from 'vue';

const provincesData = () => import('../../assets/maps/provinces.json');

export default {
  props: {
    point: {
      type: Array,
      default: () => [0, 0],
    },
    province: {
      type: String,
      default: () => null,
    },
    width: {
      type: Number,
      default: () => 300,
    },
    height: {
      type: Number,
      default: () => 375,
    },
  },

  async setup(props) {
    const { point, width, height } = toRefs(props);

    const provinces = await provincesData();
    const p = feature(provinces, provinces.objects.provincie_2019);
    const { features } = p;
    const projection = computed(() => geoMercator().fitSize([width.value, height.value], p));
    const path = computed(() => geoPath().projection(projection.value));
    const projected = computed(() => projection.value(point.value));

    const pointProps = computed(() => ((projected.value) ? {
      cx: projected.value[0],
      cy: projected.value[1],
      r: 5,
    } : {}));

    return {
      pointProps,
      path,
      projected,
      features,
    };
  },

};

</script>
<style>

.map-pane {
  display: block;

  width: 100%;
  height: auto;

  &__province {
    fill: var(--color-default-background);
    stroke: var(--color-default-foreground);
    stroke-width: var(--dimension-hair-line);

    &--current {
      fill: var(--color-primary-complement-background);
    }
  }

  &__point {
    fill: var(--color-primary-background);
    stroke: var(--color-default-foreground);
    stroke-width: var(--dimension-hair-line);
  }

}
</style>

<template>
  <section
      class="accordion-section-panel"
      :class="{'accordion-section-panel--open': modelValue}">
    <header
        class="accordion-section-panel__header">
      <button
          :id="`${name}Label`"
          class="accordion-section-panel__button"
          tabindex="0"
          :aria-expanded="modelValue"
          :aria-controls="`${name}Region`"
          @click.prevent.stop="$emit('update:model-value', !modelValue)">
        <span class="accordion-section-panel__title-container">
          <slot name="header">
            <Header4 class="accordion-section-panel__title">
              <slot name="title" />
            </Header4>
            <slot name="after-title" />
          </slot>
        </span>
        <aside
            class="accordion-section-panel__actions">
          <slot name="actions" />
          <SectionIcon
              :class="{'accordion-section-panel__icon--open': modelValue}"
              class="accordion-section-panel__icon" />
        </aside>
      </button>
    </header>
    <section
        v-if="modelValue"
        :id="`${name}Region`"
        :aria-labelledby="`${name}Label`"
        role="region"
        class="accordion-section-panel__main">
      <slot />
    </section>
  </section>
</template>
<script>
import SectionIcon from '@carbon/icons-vue/es/chevron--down/24.js';
import Header4 from '../typography/Header4.vue';

export default {
  components: {
    Header4,
    SectionIcon,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: () => false,
    },
    name: {
      type: String,
      default: () => null,
      required: true,
    },
  },
  emits: ['update:model-value'],
};
</script>
<style>
.accordion-section-panel {
  --accordion-section-action-background: transparent;
  --accordion-section-action-active-background: var(--color-secondary-complement-background);
  @supports (background-color: color-mix(in srgb, white, white)) {
    --accordion-section-action-active-background: color-mix(in srgb, var(--color-secondary-background), var(--color-default-background) 70%);
  }
  @media (any-hover: hover) {
    &__header:hover > &__button {
      background-position: bottom center;
    }
    &__header:hover + &__main {
      background-color: rgba(var(--rgb-default-foreground), .05);
    }
  }

  display: grid;
  grid-template-rows: 1fr;

  &--open {
    grid-template-rows: auto 1fr;
  }

  &__header {
    display: contents;
  }

  &__button {
    display: flex;
    gap: var(--dimension-small);
    flex-direction: row;
    align-items: center;

    padding: var(--dimension-small);

    overflow: hidden;

    color: inherit;
    background-color: transparent;

    background-image: linear-gradient(
        to bottom,
        var(--accordion-section-action-background) 0,
        var(--accordion-section-action-background) 50%,
        var(--accordion-section-action-active-background) 50%,
        var(--accordion-section-action-active-background) 100%
    );
    background-position: top center;
    background-size: 100% 200%;
    border: 0;
    outline: 0;

    cursor: pointer;

    text-align: left;

    transition: background-position var(--animation-default-duration);

    &:focus-within {
      background-color: rgba(var(--rgb-default-foreground), .05);
    }

    > :first-child {
      flex: 1;
    }
  }

  &__icon {
    transition: transform var(--animation-default-duration);
    &--open {
      transform: rotate(-180deg);
    }
  }

  &__title-container {
    display: grid;
    grid-auto-flow: column;
    gap: var(--dimension-small);
    justify-content: flex-start;
  }

  &__title {
    color: var(--color-secondary-header);

    text-decoration: underline;
    text-decoration-color: var(--color-primary-header);
    text-decoration-style: solid;
  }

  &__main {
    display: grid;
    grid-auto-flow: row;
    grid-gap: var(--dimension-medium);

    background-color: transparent;

    transition: background-color var(--animation-default-duration);
  }
}
</style>

<template>
  <LoadingButton
      v-bind="$attrs"
      :submit="true"
      :reversed="reversed"
      :indefinite="true"
      :enabled="enabled && stateType === type && !validator.$invalid && (!requiresDirty || validator.$anyDirty)"
      :type="stateType"
      :loading="loading">
    <template #icon>
      <template v-if="error">
        <ErrorIcon />
      </template>
      <template v-else-if="success">
        <SuccessIcon />
      </template>
      <slot
          v-else
          name="icon">
        <SubmitIcon />
      </slot>
    </template>
    <slot />
  </LoadingButton>
</template>

<script>
import SubmitIcon from '@carbon/icons-vue/es/upload/24.js';
import ErrorIcon from '@carbon/icons-vue/es/error/24.js';
import SuccessIcon from '@carbon/icons-vue/es/checkmark/24.js';
import {
  computed,
  toRefs,
} from 'vue';
import LoadingButton from '../buttons/LoadingButton.vue';

export default {
  components: {
    LoadingButton,
    SubmitIcon,
    ErrorIcon,
    SuccessIcon,
  },
  inheritAttrs: false,
  props: {
    ...LoadingButton.props,
    validator: {
      type: Object,
      required: true,
    },
    success: {
      type: Boolean,
      default: () => false,
    },
    requiresDirty: {
      type: Boolean,
      default: () => true,
    },
  },
  setup(props) {
    const { validator, success, type } = toRefs(props);
    const error = computed(() => !!validator.value.$error);
    return {
      error,

      stateType: computed(() => {
        if (error.value) {
          return 'error';
        }
        if (success.value) {
          return 'success';
        }
        return type.value;
      }),
    };
  },
};
</script>

<template>
  <Header4 class="subsection-panel-title">
    <slot />
  </Header4>
</template>
<script>
import Header4 from '../typography/Header4.vue';

export default {
  components: { Header4 },
};
</script>
<style>

.subsection-panel-title {
  color: var(--color-secondary-header);
}
</style>

<template>
  <LoadingButton
      v-bind="$attrs"
      :type="type"
      :submit="true"
      :enabled="enabled"
      :indefinite="indefinite"
      :loading="loading">
    <template #icon>
      <slot
          name="icon">
        <SubmitIcon />
      </slot>
    </template>
    <slot />
  </LoadingButton>
</template>

<script>
import SubmitIcon from '@carbon/icons-vue/es/upload/24.js';
import LoadingButton from '../buttons/LoadingButton.vue';

export default {
  components: { LoadingButton, SubmitIcon },
  inheritAttrs: false,
  props: {
    ...LoadingButton.props,
  },
};
</script>

<template>
  <InsetPane
      type="small"
      class="radio-block-select"
      :class="{
      'radio-block-select--selected': modelValue === option,
      'radio-block-select--disabled': !enabled
    }">
    <RadioBox
        :name="name"
        :disabled="!enabled"
        :model-value="modelValue"
        :value="option"
        :narrow="true"
        class="radio-block-select__radio"
        @update:model-value="$emit('update:model-value', option)">
      <slot name="label" />
    </RadioBox>
    <Pane
        v-if="!!$slots.default"
        class="radio-block-select__content">
      <slot :selected="modelValue === option" />
    </Pane>
  </InsetPane>
</template>

<script>

import RadioBox from '../widgets/forms/RadioBox.vue';
import InsetPane from '../panels/InsetPane.vue';
import Pane from '../panels/Pane.vue';

export default {
  components: { Pane, InsetPane, RadioBox },
  props: {
    name: {
      type: String,
      required: true,
    },
    option: {
      type: String,
      required: true,
    },
    enabled: {
      type: Boolean,
      default: () => true,
    },
    modelValue: {
      type: String,
      default: () => null,
      required: false,
    },
  },
  emits: ['update:model-value'],
};
</script>

<style>
.radio-block-select {
  display: grid;

  grid-template-rows: auto 1fr;
  gap: var(--dimension-small);

  &__content {
    margin-left: 2.5rem;

    opacity: .5;

    transition: opacity ease-in-out var(--animation-default-duration);

    pointer-events: none;
  }

  &--selected &__content {
    opacity: 1;

    pointer-events: inherit;
  }
}
</style>

<template>
  <Loader :loading="loading">
    <SelectInput
        :options="clubs"
        v-bind="$attrs"
        value-field="id"
        label-field="name"
    />
  </Loader>
</template>
<script>
import { computed } from 'vue';
import { useQuery } from '@urql/vue';
import CLUBS_QUERY from '../../../queries/clubs/clubs.graphql';
import SelectInput from './SelectInput.vue';
import Loader from '../../loading/LoaderPane.vue';

export default {
  components: { SelectInput, Loader },
  inheritAttrs: false,
  setup() {
    const clubsResult = useQuery({ query: CLUBS_QUERY });
    return {
      clubs: computed(() => clubsResult.data.value?.clubs),
      loading: clubsResult.fetching,
    };
  },
};
</script>

<template>
  <dl
      class="definition-list">
    <slot />
  </dl>
</template>
<style>
.definition-list {
    display: grid;
    grid-gap: var(--dimension-small) var(--dimension-medium);
    align-content: flex-start;
}

</style>

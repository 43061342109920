<template>
  <DetailPanel
      :top="top"
      :width="width"
      :mode="mode"
      :model-value="selectedRoute"
      :auto-hide="autoHide"
      class="navigation-detail"
      @keydown.esc="checkEscape">
    <template #default>
      <slot />
    </template>

    <template #detail>
      <slot name="detail">
        <router-view
            v-slot="{Component, route}">
          <transition
              mode="out-in"
              :name="(Component) ? 'navigation-detail__detail' : 'navigation-detail__empty'"
              @after-enter="handleChange(route)">
            <component
                :is="Component"
                v-if="Component"
                v-bind="componentProps"
                :key="route">
              <template #actions>
                <PanelAction
                    v-if="to"
                    class="navigation-detail__close">
                  <router-link
                      :to="to">
                    <Close32 />
                  </router-link>
                </PanelAction>
              </template>
            </component>
            <template v-else>
              <div
                  :key="'empty'"
                  class="navigation-detail__empty">
                <slot name="empty" />
              </div>
            </template>
          </transition>
        </router-view>
      </slot>
    </template>
  </DetailPanel>
</template>
<script>
import Close32 from '@carbon/icons-vue/es/close/32.js';
import {
  ref,
  toRefs,
} from 'vue';
import { useRouter } from 'vue-router';
import PanelAction from '../panels/PanelAction.vue';
import DetailPanel from '../panels/DetailPanel.vue';

export default {
  components: { DetailPanel, PanelAction, Close32 },
  props: {
    ...DetailPanel.props,
    to: {
      type: Object,
      default: () => null,
    },
    componentProps: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { to } = toRefs(props);
    const router = useRouter();
    const selectedRoute = ref(null);

    return {
      selectedRoute,

      checkEscape(ev) {
        if (to.value && ev.key === 'Escape') {
          ev.preventDefault();
          ev.stopPropagation();
          router.push(to.value);
        }
      },
      async handleChange(r) {
        selectedRoute.value = r;
      },
    };
  },
};
</script>
<style>

.navigation-detail {
  &__empty {
    position: relative;
    display: grid;
    flex: 1;

    &-enter-active {
      backface-visibility: hidden;

      transition: transform calc(var(--animation-default-duration) / 2) ease, opacity calc(var(--animation-default-duration) / 2) ease;
    }

    &-leave-active {
      backface-visibility: hidden;

      transition: transform calc(2 * var(--animation-default-duration)) ease, opacity calc(2 * var(--animation-default-duration)) ease;
    }

    &-enter-from,
    &-leave-to {
      opacity: 0;
      transform: translate3d(0, 0, -100px);
    }

    &-enter-to,
    &-leave-from {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
}

@media screen and (max-width: 600px) {
  .navigation-detail {
    &__close {
      position: absolute;
      top: var(--dimension-x-small);
      right: var(--dimension-x-small);
    }
  }
}
</style>

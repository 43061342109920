<script>
import {
  h,
  toRefs,
} from 'vue';

export default {
  props: {
    tag: {
      type: String,
      default: () => 'h5',
    },
  },
  setup(props, { slots }) {
    const { tag } = toRefs(props);
    return () => h(
      tag.value,
      {
        class: 'header-5',
      },
      slots.default(),
    );
  },
};
</script>
<style>

.header-5 {
  font-family: var(--font-sans-serif);
  font-size: .75rem;
  font-weight: 600;
  line-height: 110%;
}
</style>

<template>
  <div
      class="basic-pane"
      :class="{[`basic-pane--${type}`]: !!type}">
    <slot />
  </div>
</template>
<script>
export default {
  props: {
    type: {
      type: String,
      default: () => null,
    },
  },
};
</script>
<style>
.basic-pane {
  display: flex;
  gap: var(--dimension-small);
  flex-direction: column;

  &--medium {
    gap: var(--dimension-medium);
  }

  &--large {
    gap: var(--dimension-large);
  }
}
</style>

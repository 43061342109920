<template>
  <div class="panel-multi-title">
    <Header4>
      <HeaderTitle>
        <slot />
      </HeaderTitle>
    </Header4>
    <div class="panel-multi-tile__subtitle">
      <HeaderTitle>
        <slot name="subtitle" />
      </HeaderTitle>
    </div>
  </div>
</template>
<script>
import HeaderTitle from '../typography/HeaderTitle.vue';
import Header4 from '../typography/Header4.vue';

export default {
  components: { Header4, HeaderTitle },
};
</script>
<style>

.panel-multi-title {
  padding: var(--dimension-small) var(--dimension-medium);

  color: var(--color-secondary-header);

  &__subtitle {
    display: flex;
    gap: var(--dimension-small);
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }
}
</style>

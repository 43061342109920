<template>
  <div
      class="horizontal-pane">
    <slot />
  </div>
</template>
<style>
.horizontal-pane {
  display: flex;

  gap: var(--dimension-medium);
  flex-direction: row;

}
</style>

<template>
  <FlowStep
      :id="id"
      :enabled="enabled"
      :visible="visible"
      :completed="completed">
    <template #title>
      <slot
          name="title" />
    </template>
    <template #icon>
      <slot
          name="icon" />
    </template>

    <template
        #previous="{previous: parentPrevious, hasPrevious, hasNext}">
      <slot
          name="previous"
          :has-previous="hasPrevious"
          :has-next="hasNext"
          :previous-page="pager?.previous"
          :previous="parentPrevious">
        <IconButton
            v-if="hasPrevious || pager?.hasPrevious"
            :reversed="true"
            type="secondary-alt"
            @click="(pager.hasPrevious) ? pager.previous() : parentPrevious()">
          {{ t('previous') }}
        </IconButton>
      </slot>
    </template>

    <template #next="{next: parentNext, hasPrevious, hasNext}">
      <slot
          name="next"
          :next-page="pager?.next"
          :has-previous="hasPrevious"
          :has-next="hasNext"
          :next="parentNext">
        <!-- Technically, this should be empty if the FlowPanel has no next step -->
        <IconButton
            v-if="hasNext || pager?.hasNext"
            @click="(pager.hasNext) ? pager.next() : parentNext()">
          {{ t('next') }}
        </IconButton>
      </slot>
    </template>

    <PagerPane
        ref="pager"
        :model-value="modelValue"
        @update:model-value="v => $emit('update:model-value', v)">
      <slot />
    </PagerPane>
  </FlowStep>
</template>
<i18n>
{
  "nl": {
    "previous": "Vorige",
    "next": "Volgende"
  },
  "en": {
    "previous": "Previous",
    "next": "Next"
  }
}
</i18n>
<script>
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';
import IconButton from '../buttons/IconButton.vue';
import FlowStep from './FlowStep.vue';
import PagerPane from './PagerPane.vue';

export default {
  components: {
    PagerPane,
    FlowStep,
    IconButton,
  },
  props: {
    ...FlowStep.props,
    id: {
      type: String,
      default: () => null,
      required: true,
    },
    modelValue: {
      type: String,
      default: () => null,
    },
  },
  emits: ['update:model-value', 'next', 'previous'],
  setup() {
    const { t } = useI18n();
    const pager = ref(null);
    return {
      t,
      pager,
      previous() {
        return pager.value.previous();
      },
      next() {
        return pager.value.next();
      },
    };
  },
};
</script>

<template>
  <div
    class="message"
    :class="[`message--${type}`]">
    <slot />
  </div>
</template>
<script>
export default {
  props: {
    type: {
      type: String,
      default: () => 'info',
    },
  },
};
</script>

<style>
.message {
  padding: var(--dimension-medium);

  color: var(--color-white);
  background-color: var(--color-primary-background);

  &--error {
    color: var(--color-charcoal);
    background-color: var(--color-red);
  }

  &--warning {
    color: var(--color-charcoal);
    background-color: var(--color-yellow);
  }

  &--success {
    color: var(--color-white);
    background-color: var(--color-emerald);
  }

}

</style>

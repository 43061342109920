<template>
  <section class="tabbable-panel-container">
    <slot />
  </section>
</template>
<script>

export default {};
</script>

<style>
.tabbable-panel-container {
  --tabbable-tab-width: var(--dimension-single-column);

  display: flex;

  flex: 1;
  flex-direction: row;

  overflow-x: auto;
}

/*
@media (max-width: 450px) {
  .tabbable-panel-container {
    flex-direction: column;

    overflow-x: hidden;
    overflow-y: auto;

  }

}
 */
</style>

<template>
  <IconMessagePane
      class="information-message-pane">
    <template #icon>
      <slot name="icon">
        <InformationIcon />
      </slot>
    </template>
    <slot />
  </IconMessagePane>
</template>
<script>
import InformationIcon from '@carbon/icons-vue/es/information/16.js';
import IconMessagePane from './IconMessagePane.vue';

export default {
  components: {
    IconMessagePane,
    InformationIcon,
  },
};
</script>

<template>
  <Pane
      class="horizontal-tabs-container">
    <div class="horizontal-tabs-container__tabs-container">
      <ul class="horizontal-tabs-container__tabs">
        <slot />
      </ul>
      <ul class="horizontal-tabs-container__tabs">
        <slot name="right" />
      </ul>
    </div>
    <div class="horizontal-tabs-container__container">
      <slot
          v-if="!selected"
          name="empty" />
      <KeepAlive>
        <component
            :is="selected" />
      </KeepAlive>
    </div>
  </Pane>
</template>
<script>
import {
  computed,
  provide,
  ref,
  toRefs,
} from 'vue';
import Pane from '../../panels/Pane.vue';

export default {
  components: { Pane },
  props: {
    modelValue: {
      type: String,
      default: () => null,
    },
  },
  emits: ['update:model-value'],
  setup(props, { emit }) {
    const { modelValue } = toRefs(props);
    const tabs = ref([]);
    provide('tabController', {
      select(tab) {
        if (modelValue.value !== tab.id) {
          emit('update:model-value', tab.id);
        }
      },
      register(tab) {
        const idx = tabs.value.map((t) => t.id).indexOf(tab.id);
        if (idx !== -1) {
          tabs.value.splice(idx, 1, tab);
        } else {
          tabs.value.push(tab);
        }
      },
    });

    return {
      tabs,
      selected: computed(() => (modelValue.value
        ? tabs.value.find(
          (t) => t.id === modelValue.value,
        )?.slots.content || null
        : null)),
    };
  },
};
</script>
<style>
.horizontal-tabs-container {
  display: flex;
  flex-direction: column;

  &__tabs-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__tabs {
    display: flex;
    flex-direction: row;
    align-content: flex-start;

    > * {
      margin-right: var(--dimension-hair-line);
    }
  }

  &__container {
    display: flex;
    flex: 1;
    flex-direction: column;

    min-height: 0;
  }

}
</style>

<template>
  <form
      class="form-pane"
      :class="{
        'form-pane--error': !valid,
        'form-pane--dialog': asDialog,
       }"
      role="form"
      novalidate
      @submit.prevent.stop="submitHandler">
    <component
        :is="actionComponent"
        class="form-pane__pane">
      <template
          v-if="!!$slots.actions"
          #actions>
        <slot
            name="actions"
            :frozen="frozen" />
      </template>

      <template #buttons>
        <slot
            name="buttons"
            :frozen="frozen" />

        <SubmitButton
            v-if="submitLabel"
            :loading="loading"
            :type="buttonType"
            :indefinite="true"
            :enabled="valid && submitEnabled">
          <template
              #icon>
            <template v-if="!valid">
              <ErrorIcon />
            </template>
            <template v-else-if="success">
              <SuccessIcon />
            </template>
            <slot
                v-else
                name="submitIcon" />
          </template>
          {{ submitLabel }}
        </SubmitButton>
      </template>

      <div class="form-pane__content">
        <Message
            v-if="validator && validator.$error"
            ref="errors"
            type="error"
            class="form-pane__errors">
          <Paragraph>
            {{ t('errors') }}
          </Paragraph>
          <Paragraph v-if="validator && validator.$error && validator.null">
            <ul>
              <li
                  v-for="e in validator.null.$errors"
                  :key="e.$uid">
                {{ e.$message }}
              </li>
            </ul>
          </Paragraph>
        </Message>

        <Message
            v-if="failures && failures.length > 0"
            ref="errors"
            type="error"
            class="form-pane__errors">
          <Paragraph>
            {{ t('failure') }}
          </Paragraph>
          <Paragraph>
            <ul>
              <li
                  v-for="e in failures"
                  :key="e.message">
                {{ e.message }}
              </li>
            </ul>
          </Paragraph>
        </Message>

        <slot name="header"/>

        <div class="form-pane__fields">
          <slot :frozen="frozen" />
        </div>
      </div>
    </component>
  </form>
</template>
<i18n>
{
  "nl": {
    "errors": "Het formulier is niet helemaal correct ingevuld, controleer de fouten en probeer opnieuw.",
    "failure": "Het formulier kan niet verstuurd worden vanwege de volgende fout(en):"
  },
  "en": {
    "errors": "We found some errors found when validating this form, check the messages and try again.",
    "failure": "This form can not be processed because of the following error(s):"
  }
}
</i18n>
<script>
import {
  computed,
  nextTick,
  provide,
  ref,
  toRefs,
} from 'vue';
import { useI18n } from 'vue-i18n';
import Error24 from '@carbon/icons-vue/es/error/24.js';
import Checkmark24 from '@carbon/icons-vue/es/checkmark/24.js';
import ActionPane from '../panels/ActionPane.vue';
import DialogActionPane from '../panels/DialogActionPane.vue';
import Paragraph from '../typography/Paragraph.vue';
import Message from '../messages/Message.vue';
import LoadingButton from '../buttons/LoadingButton.vue';
import SubmitButton from './SubmitButton.vue';

export default {
  components: {
    SubmitButton,
    LoadingButton,
    Message,
    Paragraph,
    ErrorIcon: Error24,
    SuccessIcon: Checkmark24,
  },
  props: {
    validator: {
      type: Object,
      default: () => null,
    },
    asDialog: {
      type: Boolean,
      default: () => false,
    },
    name: {
      type: String,
      default: () => null,
    },
    submitLabel: {
      type: String,
      default: () => null,
    },
    submitType: {
      type: String,
      default: () => null,
    },
    submitEnabled: {
      type: Boolean,
      default: () => true,
    },
    submit: {
      type: Function,
      default: () => true,
    },
    externalResults: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['submit', 'error', 'success', 'update:external-results', 'update:valid'],
  setup(props, { emit }) {
    const {
      submit, name, validator, asDialog, submitType,
    } = toRefs(props);
    const { t } = useI18n();
    const loading = ref(false);
    const submitError = ref(false);
    const errors = ref(null);
    const failures = ref([]);
    const success = ref(false);

    provide('baseFieldName', computed(() => `form-${name.value || (performance.now() % 100000)}`));
    provide('withinForm', ref(true));

    const scrollToError = async () => {
      if (!errors.value) {
        await nextTick();
      }
      if (errors.value) {
        errors.value.$el.focus();
        errors.value.$el.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    };

    const valid = computed(() => !submitError.value
        && !validator.value?.$error);

    const buttonType = computed(() => {
      if (!valid.value) {
        return 'error';
      }
      if (success.value) {
        return 'success';
      }
      if (submitType.value) {
        return submitType.value;
      }
      return undefined;
    });

    return {
      t,
      loading,
      valid,
      success,
      submitError,
      errors,
      failures,
      frozen: computed(() => loading.value),
      buttonType,
      scrollToError,

      actionComponent: computed(() => ((asDialog.value) ? DialogActionPane : ActionPane)),

      async submitHandler() {
        if (!loading.value) {
          loading.value = true;
          await emit('submit');
          if (validator.value) {
            await validator.value.$validate();
          }
          if (!valid.value) {
            // Non-external validation error
            await emit('error');
          } else if (submit.value) {
            try {
              // Call submit handler and hope for the best
              await Promise.resolve(submit.value());
              await emit('success');
              success.value = true;
              setTimeout(() => {
                success.value = false;
              }, 1000);
            } catch (e) {

              // If we have a validator, it will handle the state
              if (!validator.value) {
                submitError.value = true;
              }
              if (e.failures && e.failures.length > 0) {
                failures.value = e.failures;
                submitError.value = true;
              }
              // Notify upstream that we had external errors
              await emit('update:external-results', e.state || {});
              // ERROR
              await emit('error');
            }
          }
          loading.value = false;

          if (!valid.value) {
            await scrollToError();
          }
        }

        return true;
      },
    };
  },
};
</script>
<style>

.form-pane {
  display: contents;

  &__fields {
    display: flex;
    grid-gap: var(--dimension-large);
    flex: 1;
    flex-direction: column;
    align-content: flex-start;
  }

  &__errors {
    margin: 0 0 1rem 0;
  }

  &--dialog &__content {
    padding: var(--dimension-medium);
  }
}

</style>

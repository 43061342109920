<template>
  <Tag
      v-if="isNew"
      class="new-tag"
      :title="t('since', {since: dateFormatter.format(publishedObject.publishedAt)})"
  >
    <slot :since="publishedObject.publishedAt">
      {{ t('new') }}
    </slot>
  </Tag>
</template>
<i18n>
{
  "nl": {
    "since": "Sinds {since}",
    "new": "Nieuw"
  },
  "en": {
    "since": "Since {since}",
    "new": "New"
  }
}
</i18n>
<script>
import { useI18n } from 'vue-i18n';
import {
  computed,
  toRefs,
} from 'vue';
import {
  differenceInMonths,
  startOfToday,
} from 'date-fns';
import Tag from './Tag.vue';
import { useDateFormatter } from '../../utils/date.js';

export default {
  components: { Tag },
  props: {
    publishedObject: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    // refs
    const { publishedObject } = toRefs(props);

    // uses
    const { t, locale } = useI18n();
    const dateFormatter = useDateFormatter(locale);

    const isNew = computed(() => publishedObject.value?.publishedAt && differenceInMonths(
      startOfToday(),
      publishedObject.value.publishedAt,
    ) < 1);

    return {
      t,
      isNew,
      dateFormatter,
    };
  },
};
</script>
<style>
.new-tag {
  --tag-background: var(--color-warning);
  --tag-color: var(--color-charcoal);
  justify-self: flex-start;
}
</style>

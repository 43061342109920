<template>
  <Pane
      tag="article"
      class="panel">
    <header
        v-if="$slots.actions || $slots.title || $slots.header || title"
        class="panel__header">
      <Loader
          :loading="loading">
        <slot name="header">
          <slot
              v-if="!loading"
              :title="title"
              name="title">
            <PanelTitle>
              {{ title }}
            </PanelTitle>
          </slot>
          <span v-else>&nbsp;</span>
        </slot>
        <aside
            v-if="$slots.actions"
            class="panel__actions">
          <slot
              v-if="!loading"
              name="actions" />
        </aside>
      </Loader>
    </header>
    <Pane
        tag="section"
        class="panel__main">
      <Loader
          :loading="loading">
        <slot v-if="!loading" />
      </Loader>
    </Pane>
  </Pane>
</template>
<script>
import Loader from '../loading/LoaderPane.vue';
import Pane from './Pane.vue';
import PanelTitle from './PanelTitle.vue';

export default {
  components: {
    PanelTitle,
    Pane,
    Loader,
  },
  props: {
    loading: {
      type: Boolean,
      default: () => false,
    },
    title: {
      type: String,
      default: () => null,
    },
  },
};
</script>
<style>
.panel {
  --loader-color: var(--color-secondary-complement-background);

  color: var(--color-default-foreground);
  background-color: var(--color-default-background);

  transition: box-shadow var(--animation-default-duration) linear;

  &__header {
    --loader-color: var(--color-primary-complement-background);
    display: flex;
    gap: var(--dimension-small);

    flex-shrink: 0;
    flex-direction: row;

    min-height: 3rem;

    color: var(--color-secondary-header);
    background-color: var(--color-background);

    > :first-child {
      display: inline-grid;
      flex: 1;
    }
  }

  &__actions {
    display: grid;
    grid-auto-flow: column;

    gap: var(--dimension-x-small);
    justify-content: center;
    align-content: center;

    padding: var(--dimension-small) var(--dimension-medium);
  }

  &__main {
    position: relative;
    flex: 1;
  }

}

@media screen and (max-width: 600px) {
  .panel {
    &__header {
      position: relative;
      gap: 0;
      flex-direction: column;
    }

    &__actions {
      grid-auto-flow: row;
      justify-content: stretch;
    }
  }
}
</style>

<template>
  <TextLabel
      tag="h6"
      class="card-subtitle">
    <HeaderTitle class="card-subtitle__title">
      <slot>
        {{ title }}
      </slot>
    </HeaderTitle>
  </TextLabel>
</template>
<script>
import HeaderTitle from '../typography/HeaderTitle.vue';
import TextLabel from '../labels/TextLabel.vue';

export default {
  components: { TextLabel, HeaderTitle },
  props: {
    title: {
      type: String,
      default: () => null,
    },
  },
};
</script>
<style>
.card-subtitle {
  display: flex;
  gap: var(--dimension-x-small);
  flex-direction: row;

  min-width: 0;
}
</style>

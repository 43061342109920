<template>
  <DialogActionPane
      :scrollable="false"
      class="action-card"
      :class="{
          'action-card--interactive': !!interactive,
          'action-card--selected': selected,
          [`action-card--${type}`]: !!type
      }"
      @click.stop>
    <LoaderPane :loading="loading">
      <div
          class="action-card__card"
          @click="$emit('click')">
        <header
            v-if="!!$slots.header || !!$slots.title"
            class="action-card__header">
          <slot name="header">
            <CardHeader>
              <template #title>
                <slot name="title" />
              </template>
            </CardHeader>
          </slot>
        </header>
        <slot name="content">
          <Pane class="action-card__content">
            <slot />
          </Pane>
        </slot>
      </div>
    </LoaderPane>
    <template
        v-if="$slots.actions || $slots.buttons"
        #footer>
      <footer class="action-card__footer">
        <div
            v-if="$slots.actions"
            class="action-card__actions">
          <slot name="actions" />
        </div>
        <div
            v-if="$slots.buttons"
            class="action-card__buttons">
          <slot name="buttons" />
        </div>
      </footer>
    </template>
  </DialogActionPane>
</template>
<script>
import DialogActionPane from '../panels/DialogActionPane.vue';
import LoaderPane from '../loading/LoaderPane.vue';
import CardHeader from './CardHeader.vue';
import Pane from '../panels/Pane.vue';

export default {
  components: {
    Pane, CardHeader, LoaderPane, DialogActionPane,
  },
  props: {
    interactive: {
      type: Boolean,
      default: () => false,
    },
    selected: {
      type: Boolean,
      default: () => false,
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    type: {
      type: String,
      default: () => null,
    },
  },
  emits: ['click'],
};
</script>
<style>
:root {
  --card-image: unset;
  --card-color: var(--color-primary-background);
  --card-style: solid;
  --card-background: var(--color-default-background);
  --card-loader-color: color-mix(in srgb, var(--card-color), var(--color-default-background) 75%);
  --card-hover-background: color-mix(in srgb, var(--card-color), var(--color-default-background) 90%);
  --card-selected-background: color-mix(in srgb, var(--card-color), var(--color-default-background) 65%);
}

.action-card {
  --loader-color: var(--card-loader-color);
  --card-border-color: var(--card-color);

  overflow: hidden;

  border: var(--dimension-line) solid var(--color-background);
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;

  box-shadow: 0 .25rem 1rem -.125rem rgba(var(--rgb-black), .1);

  transition: box-shadow var(--animation-default-duration), background-color var(--animation-default-duration);

  &__card {
    display: grid;
    grid-template-rows: auto 1fr;
    flex: 1;

    color: var(--color-default-foreground);

    background-color: var(--card-background);

    border-image: var(--card-image);
  }

  &--interactive {
    @media (any-hover: hover) {
      &:has(> .action-card__card:hover) {
        box-shadow: 0 .125rem .5rem -.125rem rgba(var(--rgb-black), 30%);
      }

    }
    border-left: none;
  }

  &--interactive &__card {
    @media (any-hover: hover) {
      &:hover {
        background-color: var(--card-hover-background);
      }

    }
    border-left: .25rem var(--card-style) var(--card-border-color);
    cursor: pointer;
  }

  &--selected &__card {
    background-color: var(--card-selected-background);
  }

  &__content {
    padding: var(--dimension-small);
  }

  &__footer {
    display: flex;
    gap: var(--dimension-small);
    flex-direction: row;

    padding: var(--dimension-small);

    background-color: var(--color-background);
  }

  &--interactive &__footer {
    border-left: .25rem var(--card-style) rgba(var(--rgb-default-foreground), .1);
  }

  &__buttons, &__actions {
    display: flex;
    gap: var(--dimension-small);
    flex-direction: row;
    flex-wrap: wrap;
    align-self: center;
  }

  &__buttons {
    flex: 1;
    justify-content: flex-end;
    align-self: flex-end;
  }
}
</style>

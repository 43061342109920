<template>
  <WarningMessagePane
      class="construction-message-pane">
    <template #icon>
      <ConstructionIcon />
    </template>
    <slot />
  </WarningMessagePane>
</template>
<script>
import ConstructionIcon from '@carbon/icons-vue/es/construction/16.js';
import WarningMessagePane from './WarningMessagePane.vue';

export default {
  components: {
    WarningMessagePane,
    ConstructionIcon,
  },
};
</script>

<template>
  <List
      class="select-single-list"
      :id-field="idField"
      :id-getter="idGetter"
      :items="items">
    <template #default="{item, itemId, idGetter}">
      <RadioBox
          :id="`${name}-${itemId}`"
          :model-value="idGetter(modelValue)"
          :value="idGetter(item)"
          :enabled="!enabledField || item[enabledField]"
          :name="name"
          @update:model-value="v => $emit('update:model-value', items.find(i => idGetter(i) === v))">
        <template #content="{for: forId}">
          <slot
              name="content"
              :for-id="forId"
              :item="item" />
        </template>
        <slot
            :item="item"
            :for-id="`${name}-${itemId}`"
            name="label">
          {{ item[labelField] }}
        </slot>
      </RadioBox>
    </template>
  </List>
</template>
<script>
import List from '../list/ListContainer.vue';
import RadioBox from '../widgets/forms/RadioBox.vue';

export default {
  components: { RadioBox, List },
  props: {
    ...List.props,
    labelField: {
      type: String,
      default: () => 'label',
    },
    enabledField: {
      type: String,
      default: () => 'enabled',
    },
    modelValue: {
      type: [Object, String, Number, null],
      default: () => null,
    },
    name: {
      type: String,
      default: () => null,
      required: true,
    },
  },
  emits: ['update:model-value'],
};
</script>

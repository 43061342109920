<template>
  <input
    name="csrfmiddlewaretoken"
    type="hidden"
    :value="token">
</template>
<script>
import Cookies from 'js-cookie';
import { computed } from 'vue';

export default {
  setup() {
    return {
      token: computed(() => Cookies.get('csrftoken')),
    };
  },
};
</script>

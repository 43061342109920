<template>
  <TableRow
      :class="{
      'form-table-row--errors': validator?.$error || errors.length > 0,
      'form-table-row--disabled': !enabled,
      'form-table-row--label': label || !!$slots.label,
    }"
      class="form-table-row"
      @click="select">
    <TableHeader>
      <label
          v-if="label || !!$slots.label"
          ref="labelElement"
          :for="id"
          :class="{'form-table-row__label--required': required}"
          class="form-table-row__label">
        <slot name="label">
          {{ label }}
        </slot>
      </label>
      <transition name="animation-cross-fade">
        <div
            v-if="hasErrors"
            class="form-table-row__errors">
          <ul class="form-table-row__error">
            <slot
                name="error"
                :validator="validator" />
            <li
                v-for="error in errors"
                :key="error">
              {{ error }}
            </li>
          </ul>
        </div>
      </transition>
      <div
          v-if="helpText || $slots['help-text']"
          class="form-table-row__help-text">
        <slot name="help-text">
          <p v-if="helpText">
            {{ helpText }}
          </p>
        </slot>
      </div>
    </TableHeader>
    <TableCell class="form-table-row__widget" :narrow="true">
      <div
          ref="input"
          class="form-table-row__input">
        <slot
            :id="id"
            :enabled="enabled"
            :validator="validator"
            :field-name="name" />
      </div>
    </TableCell>
    <slot name="cells" />
  </TableRow>
</template>

<script>
import {
  computed,
  inject,
  ref,
  toRefs,
} from 'vue';
import TableRow from '../table/TableRow.vue';
import TableHeader from '../table/TableHeader.vue';
import TableCell from '../table/TableCell.vue';

export default {
  components: { TableCell, TableHeader, TableRow },
  props: {
    label: {
      type: String,
      default: () => '',
    },
    name: {
      type: String,
      default: () => '',
    },
    helpText: {
      type: String,
      default: () => '',
    },
    required: {
      type: Boolean,
      default: () => false,
    },
    enabled: {
      type: Boolean,
      default: () => true,
    },
    validator: {
      type: Object,
      default: () => null,
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const { name, validator, errors } = toRefs(props);
    const baseName = inject('baseFieldName', { value: null });
    const labelElement = ref(null);
    return {
      labelElement,

      id: computed(() => `${baseName.value || 'id'}–${name.value || 'input'}`),
      hasErrors: computed(() => validator.value?.$error || errors.value?.length > 0),

      select() {
        labelElement.value?.click();
      },
    };
  },
};
</script>

<style>

.form-table-row {
  cursor: pointer;

  &:focus-within {
    /*background-color: color-mix(in srgb, var(--color-input-background), black 5%);*/
    background-color: #E2E2E2;
  }

  &--disabled {
    opacity: .4;
    filter: saturate(25%);

    pointer-events: none;
  }

  &__label {
    cursor: pointer;

    &--required {
      font-weight: 700;
    }
  }

  &__errors, &__help-text {
    padding: var(--dimension-x-small) 0;

    font-size: .6rem;
    font-weight: 400;
    line-height: 1.1;
  }

  &__errors {
    color: var(--color-error);
  }

  &__help-text {
    max-width: 60ch;

    /*color: color-mix(in srgb, var(--color-input-text), white 50%);*/
    color: #888;
  }

}
</style>

<template>
  <Tag class="sport-tag">
    <slot>
      {{ gt(`sport.${sport}`) }}
    </slot>
    <slot name="after"/>
  </Tag>
</template>
<script>
import { useI18n } from 'vue-i18n';
import Tag from './Tag.vue';

export default {
  components: { Tag },
  props: {
    sport: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { t: gt } = useI18n({ useScope: 'global' });
    return { gt };
  },
};
</script>
<style>
.sport-tag {
  --tag-background: var(--color-secondary-complement-background);
  --tag-color: var(--color-secondary-complement-foreground);
  justify-self: flex-start;
}
</style>

<template>
  <div class="form-field-read-only-value">
    <div class="form-field-read-only-value__value">
      <slot />
    </div>
    <ReadOnlyIcon
        class="form-field-read-only-value__icon"
        :title="t('readOnly')" />
  </div>
</template>
<i18n>
{
  "nl": {
    "readOnly": "Niet aan te passen"
  },
  "en": {
    "readOnly": "Read-only"
  }
}
</i18n>
<script>
import ReadOnlyIcon from '@carbon/icons-vue/es/edit--off/16.js';
import { useI18n } from 'vue-i18n';

export default {
  components: { ReadOnlyIcon },
  setup() {
    const { t } = useI18n();
    return { t };
  },
};
</script>
<style>
.form-field-read-only-value {
  display: flex;
  gap: var(--dimension-small);
  flex-direction: row;
  align-items: center;

  padding-right: var(--dimension-small);

  &__icon {
    opacity: .5;
    cursor: help;
  }

  &__value {
    flex: 1;

    min-width: 0;

    opacity: .5;
  }
}
</style>

<template>
  <section class="content-block">
    <slot />
  </section>
</template>
<style>

.content-block {
  max-width: 70ch;

  user-select: text;

  > div {
    display: contents;
  }

  a {
     word-break: break-all;
  }

  p {
    max-width: 60ch;
    margin-bottom: .5em;

    font-family: var(--font-sans-serif);
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.3;

    hyphens: auto;
  }

  strong,
  b {
    font-weight: bold;
  }

  em,
  i {
    font-style: italic;
  }

  ol {
    margin: var(--dimension-small) 0;

    > li {
      display: list-item;

      margin: var(--dimension-small) 0 var(--dimension-small) var(--dimension-medium-large);

      list-style-type: decimal;
    }
  }

  ul {
    margin: var(--dimension-small) 0;

    > li {
      display: list-item;

      margin: var(--dimension-small) 0 var(--dimension-small) var(--dimension-medium-large);

      list-style: disc;
    }
  }
}

</style>

<template>
  <div
      ref="root"
      class="detail-panel"
      :class="{[`detail-panel--${mode}`]: true, 'detail-panel--auto-hide': autoHide && !hasFocus}"
      tabindex="0"
      :style="{'--detail-panel-width': width}">
    <div class="detail-panel__container">
      <div
          class="detail-panel__main"
          autofocus
          :style="{'--detail-panel-top': top}">
        <slot />
      </div>

      <div
          class="detail-panel__detail"
          @click="hasFocus = false">
        <slot name="detail" />
      </div>
    </div>
  </div>
</template>
<script>

import {
  ref,
  toRefs,
  watch,
} from 'vue';

export default {
  props: {
    width: {
      type: String,
      default: () => 'min(var(--dimension-single-column), 40vw)',
    },
    top: {
      type: String,
      default: () => '0px',
    },
    mode: {
      type: String,
      default: () => 'right',
    },
    autoHide: {
      type: Boolean,
      default: () => false,
    },
    modelValue: {
      type: Object,
      default: () => null,
    },
  },
  setup(props) {
    const root = ref(null);
    const hasFocus = ref(true);

    const { modelValue, mode, autoHide } = toRefs(props);

    watch(modelValue, async (n, o) => {
      if (n && n !== o) {
        root.value?.scrollTo({
          left: (mode.value === 'right') ? 0 : 1000,
          behavior: 'smooth',
        });
      }
    });
    watch(autoHide, () => {
      hasFocus.value = true;
    });

    return { root, hasFocus };
  },
};
</script>
<style>
:root {
  --detail-panel-width: 0;
  --detail-panel-top: 0px;
}

.detail-panel {
  display: flex;
  flex: 1;
  flex-direction: column;

  min-height: 0;

  &__container {
    display: flex;

    flex: 1;
    flex-direction: row;

    min-height: 0;
  }

  &__main {
    position: sticky;
    top: 0;
    z-index: 10;
    display: flex;

    flex: 0 0 var(--detail-panel-width);
    flex-direction: column;

    min-height: 0;

    max-height: calc(100dvh - var(--detail-panel-top));

    color: var(--color-default-foreground);
    background-color: var(--color-background);
  }

  &__detail {
    position: relative;
    z-index: 1;
    display: flex;

    flex: 1;
    flex-direction: column;

    min-height: 0;

    overflow: hidden;

    perspective: 500px;
    perspective-origin: center center;

    &-enter-active {
      backface-visibility: hidden;

      transition: transform 80ms ease;
    }

    &-leave-active {
      backface-visibility: hidden;

      transition: transform calc(var(--animation-default-duration) * 1.5) ease, opacity calc(var(--animation-default-duration) * 1.5) ease;
    }

    &-enter-from {
      transform: translate3d(100%, 0, 0);
    }

    &-leave-to {
      opacity: 0;
      transform: translate3d(0, 0, -100px);
    }

    &-enter-to {
      transform: translate3d(0, 0, 0);
    }

    &-leave-from {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }

  }

}

@media screen and (min-width: 768px) {
  .detail-panel {
    @media (any-hover: hover) {
      &--auto-hide &__main:focus-within, &--auto-hide &__main:hover {
        flex: 0 0 var(--detail-panel-width);

        opacity: 1;
      }
    }
    &--auto-hide {
      position: relative;
    }

    &--auto-hide &__main {
      flex: 0 0 var(--dimension-medium);

      overflow: hidden;

      opacity: .1;

      transition: flex var(--animation-default-duration) ease-in-out, opacity var(--animation-default-duration) linear;

    }

    &--auto-hide &__main > :first-of-type {
      min-width: var(--detail-panel-width);

    }
  }
}
@media screen and (max-width: 768px) {
  .detail-panel {
    --detail-panel-slide-overlap: var(--dimension-large);
    --detail-panel-slide-width: calc(100vw - var(--detail-panel-slide-overlap));

    overflow-x: auto;

    &__container {
      width: calc(2 * var(--detail-panel-slide-width));
    }

    &__detail, &__main {
      flex: 1;
    }

    &--right &__detail {
      order: 1;
    }

    &--right &__main {
      order: 2;
    }

    &--top {
      --detail-panel-slide-overlap: 0;
    }

    &--top &__container {
      flex-direction: column;
    }

    &--top &__main {
      position: static;
      flex: none;
    }
  }
}

</style>

<template>
  <slot v-if="annotatedItems.length === 1">
    <slot
        name="create"
        :item="annotatedItems[0]" />
  </slot>
  <SelectSingleList
      v-else
      :items="annotatedItems"
      :name="name"
      id-field="id"
      :model-value="selected"
      @update:model-value="select">
    <template #content="{item}">
      <TextLabel
          v-if="item.id !== 'create'"
          tag="span">
        <slot
            name="label"
            :item="item.itemValue" />
      </TextLabel>
      <template v-else>
        <TextLabel tag="span">
          <slot name="different">
            {{ t('different') }}
          </slot>
        </TextLabel>
        <slot
            v-if="selected?.id === 'create'"
            name="create"
            :item="item.itemValue" />
      </template>
    </template>
  </SelectSingleList>
</template>
<i18n>
{
  "nl": {
    "different": "Anders"
  },
  "en": {
    "different": "Different"
  }
}
</i18n>
<script>
import { useI18n } from 'vue-i18n';
import {
  computed,
  toRefs,
} from 'vue';
import SelectSingleList from './SelectSingleList.vue';
import TextLabel from '../labels/TextLabel.vue';

export default {
  components: { TextLabel, SelectSingleList },
  inheritAttrs: false,
  props: {
    ...SelectSingleList.props,
  },
  emits: ['update:model-value'],
  setup(props, { emit }) {
    // refs
    const {
      items, modelValue, idGetter, idField,
    } = toRefs(props);
    // uses
    const { t } = useI18n();
    // computed
    const keyGetter = idGetter.value || ((item) => ((idField.value)
      ? (item && item[idField.value])
      : item)
    );
    const annotatedItems = computed(() => items.value.map((i) => ({
      id: `create-${keyGetter(i)}`,
      itemValue: i,
    })).concat([{ id: 'create', itemValue: null }]));

    const selected = computed(() => annotatedItems.value.find(
      (i) => keyGetter(i.itemValue) === keyGetter(modelValue.value),
    ) || { id: 'create', itemValue: null });

    return {
      t,

      selected,

      annotatedItems,

      select(value) {
        if (value.id === 'create') {
          emit('update:model-value', null);
        } else {
          emit('update:model-value', value.itemValue);
        }
      },
    };
  },
};
</script>

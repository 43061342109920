<template>
  <section
      class="icon-message-pane">
    <div class="icon-message-pane__icon">
      <slot name="icon" />
    </div>
    <div class="icon-message-pane__content">
      <slot />
    </div>
  </section>
</template>
<style>
.icon-message-pane {
  --icon-message-pane-color-foreground: var(--color-default-foreground);
  --icon-message-pane-color-background: var(--color-background);

  display: flex;
  gap: var(--dimension-medium);
  flex-direction: row;

  padding: var(--dimension-medium);

  color: var(--icon-message-pane-color-foreground);
  background-color: var(--icon-message-pane-color-background);

  &__icon svg {
    color: var(--icon-message-pane-color-foreground);
  }

  &__content {
    display: grid;
    grid-auto-flow: row;
    gap: var(--dimension-small);

    flex: 1;
    align-self: flex-start;

    p {
      font-size: .75rem;
      line-height: 1.2;
    }

    strong,
    b {
      font-weight: bold;
    }

    em,
    i {
      font-style: italic;
    }
  }

}

</style>

<template>
  <AdminBadgeIcon
      v-if="enabled && allowed"
      class="permission-icon"
      :title="title"
  />
  <LockedIcon
      v-else-if="enabled"
      class="permission-icon"
      :title="title"
  />
</template>
<i18n>
{
  "nl": {
    "needAdmin": "Je hebt een beheerder nodig voor deze taak",
    "areAdmin": "Je bent beheerder"
  }
}
</i18n>
<script>
import { useI18n } from 'vue-i18n';
import AdminBadgeIcon from '@carbon/icons-vue/es/manage-protection/20.js';
import LockedIcon from '@carbon/icons-vue/es/locked/20.js';
import {
  computed,
  toRefs,
} from 'vue';

export default {
  components: {
    AdminBadgeIcon,
    LockedIcon,
  },
  props: {
    allowedText: {
      type: String,
      default: () => null,
    },
    helpText: {
      type: String,
      default: () => null,
    },
    enabled: {
      type: Boolean,
      default: () => true,
    },
    allowed: {
      type: Boolean,
      default: () => true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const { allowed, allowedText, helpText } = toRefs(props);
    return {
      t,
      title: computed(() => {
        if (allowed.value) {
          return allowedText.value || t('areAdmin');
        }
        return helpText.value || t('needAdmin');
      }),
    };
  },
};
</script>

<style>
.permission-icon {
  align-self: center;

  cursor: help;

  vertical-align: middle;
}
</style>

<template>
  <SortableContainer
      :items="items"
      :allowed="allowed"
      :content-type="contentType"
      class="sortable-list-container"
      :context="context"
      @update:items="v => $emit('update:items', v)">
    <template #default="{items: orderedItems, allowDrop}">
      <ListContainer
          :class="{'sortable-list-container__content--drop': allowDrop, 'sortable-list-container__content': true}"
          :items="orderedItems">
        <template #item="{item, ...itemProps}">
          <slot
              name="item"
              v-bind="{item, context, contentType, ...itemProps, dragEnabled: enabled}" />
        </template>
        <template #before="beforeProps">
          <slot
              name="before"
              v-bind="beforeProps" />
        </template>
        <template #after="afterProps">
          <slot
              name="after"
              v-bind="afterProps" />
        </template>
      </ListContainer>
    </template>
  </SortableContainer>
</template>
<script>
import SortableContainer from '../draggable/SortableContainer.vue';
import ListContainer from './ListContainer.vue';

export default {
  components: {
    ListContainer,
    SortableContainer,
  },
  props: {
    ...SortableContainer.props,
    ...ListContainer.props,
    enabled: {
      type: Boolean,
      default: () => true,
    },
  },
  emits: SortableContainer.emits,
};
</script>
<style>
.sortable-list-container {
  display: flex;
  flex: 1;
  flex-direction: column;
}
</style>

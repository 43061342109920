<template>
  <section
      class="tabbable-panel"
      :class="{
        'tabbable-panel--divider': divider,
      }"
      :style="{
        '--tabbable-panel-columns': columns,
      }">
    <header
        v-if="title || !!$slots.actions || !!$slots.title"
        class="tabbable-panel__header">
      <div class="tabbable-panel__title-container">
        <slot name="title">
          <Header3 class="tabbable-panel__title">
            {{ title }}
          </Header3>
        </slot>
      </div>
      <div
          v-if="!!$slots.actions"
          class="tabbable-panel__actions">
        <slot name="actions" />
      </div>
    </header>
    <ScrollablePane
        v-if="scrollable"
        :contain="false"
        class="tabbable-panel__content">
      <slot />
    </ScrollablePane>
    <Pane
        v-else
        class="tabbable-panel__content">
      <slot />
    </Pane>
  </section>
</template>
<script>
import Header3 from '../typography/Header3.vue';
import ScrollablePane from '../panels/ScrollablePane.vue';
import Pane from '../panels/Pane.vue';

export default {
  components: { Pane, ScrollablePane, Header3 },
  props: {
    title: {
      type: String,
      default: () => null,
    },
    columns: {
      type: Number,
      default: () => 1,
    },
    divider: {
      type: Boolean,
      default: () => true,
    },
    scrollable: {
      type: Boolean,
      default: () => true,
    },
  },
};
</script>

<style>
.tabbable-panel {
  --tabbable-panel-columns: 1;

  display: flex;
  flex: 0 0 calc(var(--tabbable-panel-columns) * var(--tabbable-tab-width));
  flex-direction: column;

  min-height: 0;

  &--divider {
    border-right: 1px solid var(--color-primary-complement-background);
  }

  &:first-child:last-child {
    flex: 1;
  }

  &__header {
    display: flex;
    gap: var(--dimension-small);
    flex-direction: row;

  }

  &__title-container {
    flex: 1;

    padding: var(--dimension-small);
  }

  &__title {
    color: var(--color-secondary-header);

    text-decoration: underline;
    text-decoration-color: var(--color-primary-header);
    text-decoration-style: solid;
  }

  &__actions {
    display: grid;
    grid-auto-flow: column;
    gap: var(--dimension-x-small);
    align-items: center;

    padding: var(--dimension-x-small) var(--dimension-small);
  }

  &__content {
    flex: 1;
  }
}

/*
@media (max-width: 450px) {
  .tabbable-panel {
    flex: none;

    border-right: none;

    &__content {
      flex: none;
    }
  }

}
 */
</style>

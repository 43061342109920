<template>
  <div
      class="removable-list-item">
    <div class="removable-list-item__slot">
      <slot />
    </div>
    <button
        tabindex="0"
        type="button"
        :title="(confirm) ? t('remove') : t('removeSubmit')"
        class="removable-list-item__button"
        @click="(confirm) ? handleConfirm(item) : remove(item)">
      <DeleteIcon />
      <span>{{ (confirm) ? t('remove') : t('removeSubmit') }}</span>

      <teleport to="#modal">
        <ModalContainer
            :open="confirmItem !== null"
            @update:open="confirmItem = null">
          <DialogPanel
              type="warning"
              :title="t('remove')"
              @close="confirmItem=null">
            <Paragraph>
              <slot
                  name="removeWarning"
                  :row="confirmItem">
                {{ t('removeWarning') }}
              </slot>
            </Paragraph>
            <template #ok>
              <IconLabel
                  type="destructive"
                  :loading="removeLoading"
                  @click="remove(confirmItem)">
                <template #icon>
                  <DeleteIcon />
                </template>
                {{ t('removeSubmit') }}
              </IconLabel>
            </template>
          </DialogPanel>
        </ModalContainer>
      </teleport>
    </button>
  </div>
</template>
<i18n>
{
  "nl": {
    "removeSubmit": "Verwijder",
    "removeWarning": "Weet je zeker dat je dit wilt verwijderen?",
    "remove": "Verwijderen"
  },
  "en": {
    "removeSubmit": "Remove",
    "removeWarning": "Are you sure you want to remove this?",
    "remove": "Remove"
  }
}
</i18n>
<script>
import DeleteIcon from '@carbon/icons-vue/es/close/20.js';
import {
  ref,
  toRefs,
} from 'vue';
import { useI18n } from 'vue-i18n';
import ModalContainer from '../modal/ModalContainer.vue';
import DialogPanel from '../modal/DialogPanel.vue';
import Paragraph from '../typography/Paragraph.vue';
import IconLabel from '../labels/IconLabel.vue';

export default {
  components: {
    IconLabel,
    Paragraph,
    DialogPanel,
    ModalContainer,
    DeleteIcon,
  },
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    item: {
      type: Object,
      default: () => null,
    },
    confirm: {
      type: Boolean,
      default: () => false,
    },
    idField: {
      type: String,
      default: () => 'id',
    },
  },
  emits: ['update:model-value', 'click'],
  setup(props, { emit }) {
    const confirmItem = ref(null);
    const removeLoading = ref(false);

    const { t } = useI18n();
    const { modelValue, idField } = toRefs(props);
    return {
      confirmItem,
      removeLoading,

      t,

      handleConfirm(item) {
        confirmItem.value = item;
      },

      async remove(item) {
        removeLoading.value = true;
        const idx = (idField.value)
          ? modelValue.value.map((i) => i[idField.value]).indexOf(item[idField.value])
          : modelValue.value.indexOf(item);
        if (idx > -1 && idField.value) {
          emit('update:model-value', modelValue.value.filter((i) => item[idField.value] !== i[idField.value]));
        } else if (idx > -1 && !idField.value) {
          emit('update:model-value', modelValue.value.filter((i) => item !== i));
        }
        removeLoading.value = false;
        confirmItem.value = null;

        emit('click');
      },
    };
  },
};
</script>
<style>
.removable-list-item {
  @media (any-hover: hover) {
    &:has(.removable-list-item__button:hover) {
      background-color: rgba(var(--rgb-error), .1);
    }
  }
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;

  &__button {
    @media (any-hover: hover) {
      &:hover {
        color: var(--color-error);
        opacity: .25;
      }
    }
    margin: 0;
    padding: var(--dimension-small);

    color: inherit;
    background-color: transparent;
    border: none;
    opacity: 1;
    outline: none;
    cursor: pointer;

    transition: opacity var(--animation-default-duration) ease-in-out, color var(--animation-default-duration) linear;

    appearance: none;

    &:focus-within {
      opacity: .5;
    }

    > svg {
      display: inline-block;

      vertical-align: middle;
    }

    > span {
      display: none;
    }
  }

  &__slot {
    flex: 1;
  }
}

</style>

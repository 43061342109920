<template>
  <DialogActionPane class="crud-list">
    <ListContainer
        v-if="!!modelValue?.length"
        :items="modelValue"
        :id-field="idField">
      <template #item="{item}">
        <InteractiveListItem
            :enabled="interactive"
            @click="$emit('click', item)"
        >
          <RemovableListItem
              :item="item"
              :confirm="confirm"
              :id-field="idField"
              :model-value="modelValue"
              @update:model-value="v => $emit('update:model-value', v)">
            <slot
                name="item"
                :item="item">
              <TextLabel
                  class="crud-list__label"
                  tag="div">
                <slot
                    name="label"
                    :item="item" />
              </TextLabel>
            </slot>
          </RemovableListItem>
        </InteractiveListItem>
      </template>
    </ListContainer>
    <InsetPane
        v-if="!modelValue?.length && !!$slots.emptyLabel"
        type="small">
      <TextLabel>
        <slot name="emptyLabel" />
      </TextLabel>
    </InsetPane>
    <template
        v-if="!!$slots.actions"
        #actions>
      <slot name="actions" />
    </template>
    <template
        v-if="!!$slots.buttons || !!$slots.add"
        #buttons>
      <slot name="buttons" />
      <slot
          name="add"
          :add="add" />
    </template>
  </DialogActionPane>
</template>
<i18n>
{
  "nl": {
    "removeSubmit": "Verwijder",
    "removeWarning": "Weet je zeker dat je dit wilt verwijderen?",
    "remove": "Verwijderen"
  },
  "en": {
    "removeSubmit": "Remove",
    "removeWarning": "Are you sure you want to remove this?",
    "remove": "Remove"
  }
}
</i18n>
<script>
import { toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import ListContainer from './ListContainer.vue';
import InteractiveListItem from './InteractiveListItem.vue';
import InsetPane from '../panels/InsetPane.vue';
import TextLabel from '../labels/TextLabel.vue';
import DialogActionPane from '../panels/DialogActionPane.vue';
import RemovableListItem from './RemovableListItem.vue';

export default {
  components: {
    RemovableListItem,
    DialogActionPane,
    TextLabel,
    InsetPane,
    ListContainer,
    InteractiveListItem,
  },
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    interactive: {
      type: Boolean,
      default: () => false,
    },
    canDelete: {
      type: Boolean,
      default: () => true,
    },
    confirm: {
      type: Boolean,
      default: () => false,
    },
    idField: {
      type: String,
      default: () => 'id',
    },
  },
  emits: ['update:model-value', 'click'],
  setup(props, { emit }) {

    const { t } = useI18n();
    const { modelValue, idField } = toRefs(props);
    return {

      t,

      async add(item) {
        if (item) {
          const idx = (idField.value)
            ? modelValue.value.map((i) => i[idField.value]).indexOf(item[idField.value])
            : modelValue.value.indexOf(item);
          if (idx === -1) {
            emit('update:model-value', [...modelValue.value, item]);
          }
        }
      },
    };
  },
};
</script>
<style>
.crud-list {
  &__label {
    padding: var(--dimension-small);
  }
}
</style>

<template>
  <TabbablePanel
      class="sub-tabbable-panel-container"
      :divider="divider"
      :columns="(columns) ? columns : autoColumns"
      :title="title">
    <template #title>
      <slot name="title" />
    </template>
    <TabbablePanelContainer class="sub-tabbable-panel-container__content">
      <slot />
    </TabbablePanelContainer>
  </TabbablePanel>
</template>
<script>

import {
  onMounted,
  ref,
} from 'vue';
import TabbablePanel from './TabbablePanel.vue';
import TabbablePanelContainer from './TabbablePanelContainer.vue';

export default {
  components: { TabbablePanelContainer, TabbablePanel },
  props: TabbablePanel.props,
  setup(props, { slots }) {
    const autoColumns = ref(1);

    onMounted(() => {
      autoColumns.value = slots.default().length;
    });

    return { autoColumns };
  },
};
</script>

<style>
.sub-tabbable-panel-container {
  &__content {
    overflow-x: hidden;
  }
}
</style>

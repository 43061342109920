<template>
  <transition name="modal-">
    <main
        v-if="open"
        class="modal"
        tabindex="0"
        :class="{
        'modal--fixed': fixed,
      }"
        @keydown="checkEscape"
        @click="checkClose">
      <div
          v-if="open"
          class="modal__wrapper"
          @click="checkPrevent">
        <slot />
      </div>
    </main>
  </transition>
</template>
<script>
export default {
  props: {
    open: {
      type: Boolean,
      default: () => true,
    },
    fixed: {
      type: Boolean,
      default: () => true,
    },
    closeOnClick: {
      type: Boolean,
      default: () => true,
    },
    closeOnEscape: {
      type: Boolean,
      default: () => true,
    },
  },
  emits: ['update:open'],
  watch: {
    open(o) {
      if (o) {
        this.$nextTick(() => {
          if (this.open && this.$el.nodeType === 1) {
            this.$el.focus();
          }
        });
      }
    },
  },
  mounted() {
    if (this.open && this.$el.nodeType === 1) {
      this.$el.focus();
    }
  },
  methods: {
    checkClose(ev) {
      if (this.closeOnClick) {
        ev.preventDefault();
        ev.stopPropagation();
        this.$emit('update:open', false);
      }
    },
    checkPrevent(ev) {
      if (this.closeOnClick) {
        // We capture it since we don't want to catch it for close
        ev.preventDefault();
        ev.stopPropagation();
      }
    },
    checkEscape(ev) {
      if (this.closeOnEscape && ev.key === 'Escape') {
        ev.preventDefault();
        ev.stopPropagation();
        this.$emit('update:open', false);
      }
    },
  },
};
</script>
<style>
.modal {
  display: grid;
  justify-content: center;
  align-content: center;

  background-color: rgba(var(--rgb-black), .75);

  &__wrapper {
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
  }

  &--fixed {
    position: fixed;
    top: 0;
    z-index: var(--layer-modal);

    width: 100vw;
    height: 100dvh;
  }

  &--enter-active,
  &--leave-active {
    transition: opacity calc(2 * var(--animation-default-duration)) ease;
  }

  &--enter-active &__wrapper,
  &--leave-active &__wrapper {
    transition: transform var(--animation-default-duration) ease;
  }

  &--enter-from,
  &--leave-to {
    opacity: 0;
  }

  &--enter-from &__wrapper,
  &--leave-to &__wrapper {
    transform: translate3d(0, 10%, 0);
  }
}

</style>

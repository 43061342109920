<template>
  <div
      class="grid-layout"
      :class="{'grid-layout--fit': fit}"
      :style="{
    '--grid-layout-column-size': columnSize,
    '--grid-layout-column-gap': gap,
  }">
    <slot />
  </div>
</template>
<script>
export default {
  props: {
    columnSize: {
      type: String,
      default: () => undefined,
    },
    gap: {
      type: String,
      default: () => undefined,
    },
    fit: {
      type: Boolean,
      default: () => false,
    },
  },
};
</script>
<style>
:root {
  --grid-layout-column-size: 12.5rem;
  --grid-layout-column-gap: var(--dimension-medium);
}
.grid-layout {
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: repeat(auto-fill, minmax(var(--grid-layout-column-size), 1fr));
  gap: var(--grid-layout-column-gap);

  &--fit {
    grid-template-columns: repeat(auto-fit, minmax(var(--grid-layout-column-size), 1fr));
  }
}
</style>

<template>
  <table class="table-container">
    <slot />
  </table>
</template>
<style>
.table-container {
  align-self: flex-start;

  width: 100%;

  background-color: var(--color-background);

  border: var(--dimension-line) solid var(--color-background);

  text-align: left;

  user-select: text;
}

</style>

<template>
  <TextLabel
      class="error-label"
      tag="ul">
    <slot />
  </TextLabel>
</template>
<script>
import TextLabel from './TextLabel.vue';

export default {
  components: {
    TextLabel,
  },
};
</script>
<style>
.error-label {
  display: flex;
  gap: var(--dimension-xx-small);
  flex-direction: column;

  max-width: var(--dimension-input-width);
  padding: var(--dimension-x-small);

  color: var(--color-default-foreground);
  background-color: var(--color-red);

  list-style: none;

}
</style>

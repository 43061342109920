<template>
  <IconButton
      class="loading-button"
      :loading="loading"
      :enabled="enabled && !loading"
      :reversed="reversed"
      :icon-only="iconOnly"
      :class="{
        'loading-button--loading': loading,
        'loading-button--indefinite': loading && indefinite,
      }"
      :submit="submit"
      :type="type"
      :style="{'backgroundPosition': (loading && !indefinite) ? `${(1 - percentage) * 100}% 0` : undefined} "
      @click="(ev) => $emit('click', ev)">
    <slot />

    <template
        v-if="!loading"
        #icon>
      <slot name="icon" />
    </template>
    <template
        v-else
        #icon-loader>
      <slot name="icon-loader" />
    </template>
    <template #sections>
      <slot name="sections" />
    </template>
  </IconButton>
</template>
<script>
import IconButton from './IconButton.vue';

export default {
  components: { IconButton },
  props: {
    ...IconButton.props,
    loading: {
      type: Boolean,
      default: () => false,
    },
    indefinite: {
      type: Boolean,
      default: () => false,
    },
    percentage: {
      type: Number,
      default: () => 0,
    },
  },
  emits: ['click'],
};
</script>
<style>
.loading-button {
  &--loading {
    background-image: linear-gradient(
        to right,
        var(--button-icon-background) 0,
        var(--button-icon-background) 50%,
        var(--button-background) 50%,
        var(--button-background) 100%
    );
    background-position: 0 0;
    background-size: 200% 100%;

    pointer-events: none;

    /*noinspection CssUnusedSymbol*/

    .button__icon {
      background-color: transparent;
    }
  }

  &--indefinite {
    background-image: linear-gradient(
        to right,
        var(--button-background) 0%,
        var(--button-background) 25%,
        var(--button-icon-background) 25%,
        var(--button-icon-background) 75%,
        var(--button-background) 75%,
        var(--button-background) 100%
    );
    background-size: 400% 100%;

    animation-name: button-loading-indefinite;
    animation-duration: calc(8 * var(--animation-default-duration));
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-fill-mode: both;
  }
}

@keyframes button-loading-indefinite {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: 0 0;
  }
}
</style>

<template>
  <IconMessagePane
      class="warning-message-pane"
      style="
        --icon-message-pane-color-background: var(--color-warning);
      ">
    <template #icon>
      <slot name="icon">
        <WarningIcon />
      </slot>
    </template>
    <slot />
  </IconMessagePane>
</template>
<script>
import WarningIcon from '@carbon/icons-vue/es/warning/16.js';
import IconMessagePane from './IconMessagePane.vue';

export default {
  components: {
    IconMessagePane,
    WarningIcon,
  },
};
</script>

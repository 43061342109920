<template>
  <MessagePane
      v-if="failures?.length"
      ref="root"
      type="error"
      class="form-message-pane">
    <Paragraph>
      {{ t('failure') }}
    </Paragraph>
    <ul>
      <li
          v-for="e in failures"
          :key="e.message">
        {{ e.message }}
      </li>
    </ul>
  </MessagePane>
  <MessagePane
      v-else-if="validator.$error"
      ref="root"
      type="error"
      class="form-message-pane">
    <Paragraph>
      {{ t('errors') }}
    </Paragraph>
  </MessagePane>
  <MessagePane
      v-if="validator.null?.$errors.length"
      type="error">
    <ul>
      <li
          v-for="e in validator.null.$errors"
          :key="e.$uid">
        {{ e.$message }}
      </li>
    </ul>
  </MessagePane>
</template>
<i18n>
{
  "nl": {
    "errors": "Het formulier is niet helemaal correct ingevuld, controleer de fouten en probeer opnieuw.",
    "failure": "Het formulier kan niet verstuurd worden vanwege de volgende fout(en):"
  },
  "en": {
    "errors": "The form encountered some errors when validating the information, please try again.",
    "failure": "The form cannot be sent because of the following error(s):"
  }
}
</i18n>
<script>
import { useI18n } from 'vue-i18n';
import {
  computed,
  nextTick,
  ref,
  toRefs,
  watch,
} from 'vue';
import MessagePane from '../messages/MessagePane.vue';
import Paragraph from '../typography/Paragraph.vue';

export default {
  components: { Paragraph, MessagePane },
  props: {
    validator: {
      type: Object,
      required: true,
    },
    failures: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const { validator, failures } = toRefs(props);
    const hasErrors = computed(() => validator.value?.$error || failures.value?.length);
    const root = ref(null);

    const scrollToError = async () => {
      if (!root.value) {
        await nextTick();
      }
      if (root.value) {
        root.value.$el.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    };

    watch(hasErrors, (has) => {
      if (has) {
        scrollToError();
      }
    });

    return {
      t,
      root,
    };
  },
};
</script>

<template>
  <TableRow
      class="interactive-table-row"
      :class="{'interactive-table-row--enabled': enabled}"
      :row="row"
      :index="index"
      @click="(enabled) ? $emit('click', row) : undefined">
    <template #default="{row: r}">
      <slot :row="r" />
    </template>
  </TableRow>
</template>
<script>
import TableRow from './TableRow.vue';

export default {
  components: { TableRow },

  props: {
    ...TableRow.props,
    enabled: {
      type: Boolean,
      default: () => true,
    },
  },
  emits: ['click'],
};
</script>
<style>
.interactive-table-row {
  --interactive-table-row-size: 100%;

  background-color: transparent;
  background-image: linear-gradient(
      to bottom,
      var(--table-row-background) 0%,
      var(--table-row-background) 50%,
      color-mix(in srgb, var(--table-row-alternate-background), var(--color-default-background)) 50%,
      color-mix(in srgb, var(--table-row-alternate-background), var(--color-default-background)) 100%
  );
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: var(--interactive-table-row-size) 500%;

  transition: background-position calc(var(--animation-default-duration) / 2) ease-in-out, color calc(var(--animation-default-duration) / 2) ease-in-out;

  user-select: none;

  &--enabled {
    @media (any-hover: hover) {
      &:hover {
        background-position: 0 100%;
      }
    }

    cursor: pointer;

  }

  &:nth-child(2n +1) {
    background-color: transparent;
  }
  &:nth-child(2n) {
    background-color: transparent;
    background-image: linear-gradient(
        to bottom,
        var(--table-row-alternate-background) 0%,
        var(--table-row-alternate-background) 50%,
        color-mix(in srgb, var(--table-row-alternate-background), var(--color-default-background)) 50%,
        color-mix(in srgb, var(--table-row-alternate-background), var(--color-default-background)) 100%
    );
  }
}
</style>

<template>
  <Pane
      tag="section"
      class="subsection-panel">
    <header class="subsection-panel__header">
      <slot name="header">
        <div class="subsection-panel__title">
          <slot
              v-if="!loading"
              name="title">
            <SubsectionPanelTitle>
              {{ title }}
            </SubsectionPanelTitle>
          </slot>
          <span v-else>&nbsp;</span>
        </div>
      </slot>
      <aside
          v-if="$slots.actions"
          class="subsection-panel__actions">
        <slot name="actions" />
      </aside>
    </header>
    <Pane
        class="subsection-panel__main">
      <slot />
    </Pane>
  </Pane>
</template>
<script>
import Pane from './Pane.vue';
import SubsectionPanelTitle from './SubsectionPanelTitle.vue';

export default {
  components: { SubsectionPanelTitle, Pane },
  props: {
    loading: {
      type: Boolean,
      default: () => false,
    },
    title: {
      type: String,
      default: () => null,
    },
  },
};
</script>
<style>
.subsection-panel {
  gap: var(--dimension-small);

  &__header {
    display: flex;
    gap: var(--dimension-small);
    flex-direction: row;

    align-items: flex-end;

    min-width: 0;

  }

  &__title {
    flex: 1;
  }

  &__main {
    gap: var(--dimension-small);
    flex: 1;
  }

  &__actions {
    display: flex;
    gap: var(--dimension-x-small);
    flex-direction: row;
    justify-self: flex-end;
  }

}
</style>

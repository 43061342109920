<template>
  <div
      class="message-pane"
      :class="{
        [`message-pane--${type}`]: true,
        'message-pane--actions': !!$slots.actions,
        'message-pane--narrow': narrow,
        }">
    <div class="message-pane__icon">
      <InfoIcon v-if="type === 'info'" />
    </div>
    <div class="message-pane__content">
      <slot />
    </div>
    <div
        v-if="!!$slots.actions"
        class="message-pane__actions">
      <slot name="actions" />
    </div>
  </div>
</template>
<script>
import InfoIcon from '@carbon/icons-vue/es/information/16.js';

export default {
  components: { InfoIcon },
  props: {
    type: {
      type: String,
      default: () => 'info',
    },
    narrow: {
      type: Boolean,
      default: () => false,
    },
  },
};
</script>

<style>
.message-pane {
  display: flex;
  gap: var(--dimension-medium);
  flex-direction: row;

  padding: var(--dimension-medium);

  color: var(--color-white);
  background-color: var(--color-primary-background);

  &--narrow {
    flex-direction: column;
  }

  &--error {
    color: var(--color-charcoal);
    background-color: var(--color-red);
  }

  &--warning {
    color: var(--color-charcoal);
    background-color: var(--color-yellow);
  }

  &--success {
    color: var(--color-white);
    background-color: var(--color-emerald);
  }

  &--info {
    color: var(--color-charcoal);
    background-color: var(--color-background);
  }

  &__actions {
    display: grid;
    grid-auto-flow: column;
    gap: var(--dimension-small);

    justify-content: flex-end;
    align-content: flex-end;
  }

  &__icon:empty {
    display: none;
  }

  &__content {
    display: grid;
    grid-auto-flow: row;
    gap: var(--dimension-small);

    flex: 1;
    align-self: flex-start;

    strong,
    b {
      font-weight: bold;
    }

    em,
    i {
      font-style: italic;
    }
  }

}

@media (max-width: 450px) {
  .message-pane {
    flex-direction: column;
  }

}

</style>

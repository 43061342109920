<template>
  <FieldSet class="dutch-identify-field-set">
    <template #title>
      <slot name="title" />
    </template>
    <Field
        :validator="validator.dutchId"
        name="dutchId">
      <template #default="{ id, fieldName }">
        <SelectSingleList
            v-if="address?.country?.id !== 'NL'"
            :id="id"
            :model-value="{id: `${dutchId}`}"
            :name="fieldName"
            :items="[
                {
          id: 'true',
          label: t('dutchId.label')
        },
                {
          id: 'false',
          label: t('dutchId.labelNegative')
        }
        ]"
            @update:model-value="v => setValue(v.id === 'true')" />
        <CheckBox
            v-else
            :id="id"
            v-model="dutchId"
            :label="t('dutchId.label')"
            :name="fieldName"
            @update:model-value="$emit('update:model-value', dutchId || adoptedNationality )" />
      </template>
    </Field>

    <transition name="animation-cross-fade">
      <Field
          v-if="!dutchId && address.country.id === 'NL'"
          :validator="validator.adoptedNationality"
          name="adoptedNationality">
        <template #default="{ id, fieldName }">
          <CheckBox
              :id="id"
              v-model="adoptedNationality"
              :label="t('dutchId.labelAdopted')"
              :name="fieldName"
              @update:model-value="$emit('update:model-value', dutchId || adoptedNationality )" />
        </template>
      </Field>
    </transition>
  </FieldSet>
</template>
<i18n>
{
  "nl": {
    "dutchId": {
      "label": "Ja, ik heb een Nederlands identiteitsbewijs.",
      "labelNegative": "Nee, ik heb geen Nederlands identiteitsbewijs.",
      "labelAdopted": "Ik woon al vijf jaar of langer in Nederland."
    }
  },
  "en": {
    "dutchId": {
      "label": "Yes, I have a Dutch identification document.",
      "labelNegative": "No, I do not have a Dutch identification document.",
      "labelAdopted": "I have been a resident of the Netherlands for the last five years."
    }
  }
}
</i18n>
<script>
import {
  computed,
  ref,
  toRefs,
  watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useVuelidate } from '@vuelidate/core';
import FieldSet from './FieldSet.vue';
import Field from '../fields/FormField.vue';
import CheckBox from '../widgets/forms/CheckBox.vue';
import SelectSingleList from '../forms/SelectSingleList.vue';

export default {
  components: {
    SelectSingleList,
    CheckBox,
    Field,
    FieldSet,
  },
  props: {
    address: {
      type: Object,
      required: true,
    },
    modelValue: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['update:model-value'],
  setup(props, { emit }) {
    const { modelValue } = toRefs(props);

    const dutchId = ref(modelValue.value);
    const adoptedNationality = ref(false);

    const { t } = useI18n();

    const rules = computed(() => ({
      dutchId: {},
      adoptedNationality: {},
    }));

    watch(modelValue, (v) => {
      if (!v && (dutchId.value || adoptedNationality.value)) {
        dutchId.value = false;
        adoptedNationality.value = false;
      }
    });

    const validator = useVuelidate(rules, {
      dutchId, adoptedNationality,
    });

    const setValue = (v) => {
      dutchId.value = v;
      emit('update:model-value', dutchId.value || adoptedNationality.value);
    };

    return {
      t,
      validator,
      dutchId,
      adoptedNationality,
      setValue,
    };
  },
};
</script>

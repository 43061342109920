<template>
  <div class="image-thumbnail">
    <img
        :src="path"
        class="image-thumbnail__thumbnail"
        :alt="alt"
        @click="open=true">

    <teleport to="#modal">
      <ModalContainer v-model:open="open">
        <WindowPanel
            class="image-thumbnail__window"
            :title="title"
            @close="open=false">
          <a
              :href="path"
              rel="nofollow noopener"
              target="_blank">
            <img
                :src="path"
                class="image-thumbnail__image"
                :alt="alt">
          </a>
        </WindowPanel>
      </ModalContainer>
    </teleport>
  </div>
</template>
<i18n>
{
  "nl": {
  }
}
</i18n>
<script>
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';
import ModalContainer from '../modal/ModalContainer.vue';
import WindowPanel from '../modal/WindowPanel.vue';

export default {
  components: { WindowPanel, ModalContainer },
  props: {
    path: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n();
    const open = ref(false);
    return {
      t,

      open,
    };
  },
};
</script>
<style>
.image-thumbnail {
  &__thumbnail {
    @media (any-hover: hover) {
      &:hover {
        opacity: .75;
      }

    }
    display: block;

    max-width: 8rem;

    border: var(--dimension-thick-line) solid var(--color-primary-background);

    cursor: pointer;

    transition: opacity var(--animation-default-duration);
  }

  &__image {
    display: block;

    max-width: 90vw;
    max-height: calc(90vh - 10rem);
    object-fit: cover;
  }
}
</style>

<template>
  <div class="sticky-pane">
    <slot />
  </div>
</template>
<style>
.sticky-pane {
  position: sticky;
  top: 0;
  z-index: var(--layer-menu);
}
</style>

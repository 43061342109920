<template>
  <Pane
      class="preview-pane"
      :class="{'preview-pane--centered': centered}">
    <slot />
  </Pane>
</template>
<script>
import Pane from './Pane.vue';

export default {
  components: { Pane },
  props: {
    centered: {
      type: Boolean,
      default: () => true,
    },
  },
};
</script>
<style>
.preview-pane {
  flex: 1;

  padding: var(--dimension-small);

  background-color: color-mix(in srgb, var(--color-default-background), var(--color-default-foreground) 25%);

  &--centered {
    justify-content: center;
    align-items: center;
  }
}
</style>

<template>
  <div
      class="object-search-pane"
      :class="[`object-search-pane--align-${align}`]">
    <div class="object-search-pane__input">
      <TextInput
          ref="searchEl"
          :model-value="q"
          :placeholder="placeholder"
          @update:model-value="v => $emit('update:q', v)"
          @keydown="handleKeyInput" />
      <slot />
      <SearchIcon class="object-search-pane__icon" />
    </div>
    <div
        v-if="q"
        :style="{maxHeight}"
        class="object-search-pane__results">
      <LoaderPane :loading="loading">
        <ScrollablePane>
          <slot
              name="container"
              :items="selectedResults"
              :go="go">
            <ListContainer
                ref="list"
                :items="selectedResults">
              <template #item="{item}">
                <SelectableListItem
                    :selected="item.selected"
                    class="object-search-pane__item"
                    @select="go(item)">
                  <slot
                      name="item"
                      :item="item" />
                </SelectableListItem>
              </template>
            </ListContainer>
          </slot>
          <EmptyPane v-if="!loading && selectedResults?.length === 0">
            {{ t('nothingFound', { q }) }}
          </EmptyPane>
        </ScrollablePane>
      </LoaderPane>
    </div>
  </div>
</template>
<i18n>
{
  "nl": {
    "nothingFound": "Niets gevonden voor `{q}`"
  }
}
</i18n>
<script>
import { useI18n } from 'vue-i18n';
import {
  computed,
  ref,
  toRefs,
} from 'vue';
import SearchIcon from '@carbon/icons-vue/es/search/24';
import TextInput from '../widgets/forms/TextInput.vue';
import ScrollablePane from '../panels/ScrollablePane.vue';
import ListContainer from '../list/ListContainer.vue';
import LoaderPane from '../loading/LoaderPane.vue';
import SelectableListItem from '../list/SelectableListItem.vue';
import EmptyPane from '../panels/EmptyPane.vue';

export default {
  components: {
    EmptyPane,
    SelectableListItem,
    LoaderPane,
    ListContainer,
    ScrollablePane,
    TextInput,
    SearchIcon,
  },
  props: {
    results: {
      type: Array,
      required: true,
    },
    q: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: () => null,
    },
    maxHeight: {
      type: String,
      default: () => undefined,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    align: {
      type: String,
      default: () => 'contain',
    },
  },
  emits: ['search', 'select', 'update:q'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const searchEl = ref(null);
    const list = ref(null);
    const selected = ref(-1);

    const { results, q } = toRefs(props);

    const go = async (item) => {
      emit('select', item);
      selected.value = -1;
      searchEl.value?.focus();
      searchEl.value?.blur();
    };

    return {
      t,
      searchEl,
      list,
      go,

      selectedResults: computed(() => results.value?.map(
        (r, idx) => ({ ...r, selected: selected.value === idx }),
      )),
      focus() {
        searchEl.value?.focus();
      },
      handleKeyInput(ev) {
        if (ev.key === 'Escape') {
          ev.preventDefault();
          ev.stopPropagation();
          if (q.value === '') {
            ev.target.blur();
          } else {
            emit('update:q', '');
          }
        } else if (ev.key === 'ArrowDown') {
          selected.value = (selected.value >= results.value.length - 1) ? 0 : selected.value + 1;
          list.value?.scrollToItem(selected.value);
          ev.preventDefault();
          ev.stopPropagation();
        } else if (ev.key === 'ArrowUp') {
          selected.value = (selected.value <= 0) ? results.value.length - 1 : selected.value - 1;
          list.value?.scrollToItem(selected.value);
          ev.preventDefault();
          ev.stopPropagation();
        } else if (ev.key === 'Enter') {
          if (results.value && results.value[selected.value]) {
            go(results.value[selected.value]);
          }
          ev.preventDefault();
          ev.stopPropagation();
        }
      },
    };
  },
};
</script>
<style>
.object-search-pane {
  position: relative;

  &__input {
    display: flex;
    flex-direction: row;
    align-items: center;

    color: rgba(var(--rgb-default-foreground), .25);
    background-color: rgba(var(--rgb-default-background), .5);

  }

  &__icon {
    margin: 0 var(--dimension-x-small);
  }

  &__results {
    position: absolute;
    z-index: var(--layer-popover);
    display: none;

    max-height: calc(100dvh - 15rem);

    color: var(--color-default-foreground);

    background-color: var(--color-background);
    border-top: var(--dimension-line) solid var(--color-primary-background);
    box-shadow: 0 .25rem 1rem -.125rem rgba(var(--rgb-default-foreground), .1);

  }

  &--align-contain &__results {
    right: 0;
    left: 0;
  }

  &--align-left &__results {
    left: 0;
  }

  &__item {
    padding: var(--dimension-small);
  }

  &:focus-within &__input {
    color: var(--color-default-foreground);
    background-color: rgba(var(--rgb-default-background), .6);
  }

  &:focus-within &__results {
    display: flex;
  }

}

@media screen and (max-width: 768px) {
  .object-search-pane {
    position: static;

    &__results {
      right: var(--dimension-small);
      left: var(--dimension-small);

    }
  }
}
</style>

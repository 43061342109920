<script>
import {
  h,
  toRefs,
} from 'vue';

export default {
  props: {
    tag: {
      type: String,
      default: () => 'h4',
    },
  },
  setup(props, { slots }) {
    const { tag } = toRefs(props);
    return () => h(
      tag.value,
      {
        class: 'header-4',
      },
      slots.default(),
    );
  },
};
</script>
<style>

.header-4 {
  font-family: var(--font-header);
  font-size: .75rem;
  font-weight: normal;
  line-height: 110%;
}
</style>

<template>
  <Header1 class="page-title">
    <slot />
  </Header1>
</template>
<script>
import Header1 from '../typography/Header1.vue';

export default {
  components: { Header1 },
};
</script>
<style>

.page-title {
  display: grid;

  color: var(--color-default-foreground);

}
</style>

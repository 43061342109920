<template>
  <router-link
      class="logo-link-pane"
      :to="to || {name: `${locale}:home`}">
    <LogoPane />
  </router-link>
</template>
<script>
import { useI18n } from 'vue-i18n';
import LogoPane from './LogoPane.vue';

export default {
  components: { LogoPane },
  props: {
    to: {
      type: Object,
      default: () => null,
    },
  },
  setup() {
    const { locale } = useI18n();
    return { locale };
  },
};
</script>
<style>
.logo-link-pane {
  @media (any-hover: hover) {
    &:hover {
      opacity: .75;
    }
  }

  color: inherit;

  text-decoration: none;

  transition: opacity var(--animation-default-duration);

  * {
    cursor: pointer;
  }

}

</style>

<template>
  <div class="address-pane">
    <Header5
        v-if="name"
        class="address-pane__name">
      {{ name }}
    </Header5>
    <Paragraph class="address-pane__address">
      <span class="address-pane__address-part">
      {{ address.address }}
      </span><br>
      <span class="address-pane__address-part">
      {{ address.postalCode }}
      </span>
      <span class="address-pane__address-part">
      {{ address.city }}
      </span><br>

      <span class="address-pane__address-part">
      <template
          v-if="address.country.id !== 'NL'">
        {{ address.country.name }}
      </template>
      <template
          v-else>
        {{ address.province }}
      </template>
      </span><br>
      <template
          v-if="phoneNumber">
        <a :href="`tel:${phoneNumber}`">{{ phoneNumber }}</a>
      </template>
    </Paragraph>
  </div>
</template>
<script>

import gql from 'graphql-tag';
import Paragraph from '../typography/Paragraph.vue';
import Header5 from '../typography/Header5.vue';

export const ADDRESS_FRAGMENT = gql`
fragment AddressFields on Address {
  address
  postalCode
  city
  town
  province
  country {
    id
    name  
  }
  latitude
  longitude
}
`;
export default {
  components: { Header5, Paragraph },
  props: {
    address: {
      type: Object,
      default: () => null,
    },
    name: {
      type: String,
      default: () => null,
    },
    phoneNumber: {
      type: String,
      default: () => null,
    },
  },
};
</script>
<style>
.address-pane {

  &__address-part {
    display: inline-block;

    margin-right: var(--dimension-x-small);

    user-select: text;
  }
}
</style>

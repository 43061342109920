<template>
  <InputField
      v-bind="{...$attrs, ...$props}"
      ref="input"
      :placeholder="placeholder"
      :value="modelValue"
      class="text-input"
      @input="$emit('update:model-value', $event.target.value, $event)"
  />
</template>

<script>
import { ref } from 'vue';
import InputField from './Input.vue';

export default {
  components: { InputField },
  inheritAttrs: false,
  props: {
    ...InputField.props,
    modelValue: {
      type: String,
      default: () => null,
    },
    placeholder: {
      type: String,
      default: () => '',
    },
  },
  emits: ['update:model-value'],
  setup() {
    const input = ref(null);
    return {
      input,
      select() {
        input.value?.select();
      },
      focus() {
        input.value?.focus();
      },
      blur() {
        input.value?.blur();
      },
    };
  },
};
</script>

<style>
.text-input {
  padding: 0 .5rem;

  &::placeholder,
  &::-moz-placeholder {
    color: color-mix(in srgb, var(--color-default-background), var(--color-default-foreground) 30%);
    opacity: 1;

    text-overflow: ellipsis;
  }
}
</style>

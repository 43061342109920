<template>
  <TableContainer
      class="select-table">
    <TableContent :rows="rows">
      <template
          v-if="$slots.header && rows && rows.length > 0"
          #header>
        <slot name="header" />
      </template>
      <template #row="{row, index}">
        <SelectableTableRow
            :selected="isSelected(row)"
            @click="select(row)">
          <slot
              name="row"
              :index="index"
              :row="row" />
        </SelectableTableRow>
      </template>
      <template #empty>
        <slot name="empty" />
      </template>
    </TableContent>
  </TableContainer>
</template>
<i18n>
{
  "nl": {
  }
}
</i18n>
<script>
import { useI18n } from 'vue-i18n';
import { toRefs } from 'vue';
import TableContainer from './TableContainer.vue';
import TableContent from './TableContent.vue';
import SelectableTableRow from './SelectableTableRow.vue';

export default {
  components: {
    SelectableTableRow,
    TableContent,
    TableContainer,
  },
  props: {
    ...TableContent.props,
    modelValue: {
      type: [Array, Object, null],
      required: true,
    },
    idField: {
      type: String,
      default: () => 'id',
    },
    multiple: {
      type: Boolean,
      default: () => true,
    },
  },
  emits: ['update:model-value'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const {
      modelValue, multiple, rows, idField,
    } = toRefs(props);
    return {
      t,

      isSelected: (row) => ((multiple.value)
        ? !!modelValue.value?.find((r) => row[idField.value] === r[idField.value])
        : (!!modelValue.value && modelValue.value[idField.value] === row[idField.value])),
      select(row) {
        if (multiple.value) {
          const value = modelValue.value?.slice() || [];
          const idx = value.indexOf(row);
          if (idx !== -1) {
            value.splice(idx, 1);
            emit('update:model-value', value);
          } else {
            value.push(row);
            emit('update:model-value', rows.value.filter((r) => value.find((rr) => rr === r)));
          }
        } else {
          emit('update:model-value', row);
        }
      },
    };
  },
};
</script>

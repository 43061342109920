<template>
  <SearchSelectInput
      v-model:q="q"
      :model-value="modelValue"
      class="user-search-select-input"
      :results="results"
      :loading="loading"
      :placeholder="t('searchUser')"
      @update:model-value="v => $emit('update:model-value', v)">
    <template #item="{item}">
      <UserWidget
          v-if="item"
          :user="item" />
    </template>
  </SearchSelectInput>
</template>
<i18n>
{
  "nl": {
    "searchUser": "Zoek een account"
  },
  "en": {
    "searchCombination": "Find an account"
  }
}
</i18n>
<script>
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import gql from 'graphql-tag';
import { useQuery } from '@urql/vue';
import { useDebouncedRef } from '../../utils/refs.js';
import SearchSelectInput from '../forms/SearchSelectInput.vue';
import UserWidget from './UserWidget.vue';

const SEARCH_QUERY = gql`
query UserSearch($q: String!) {
  userSearch(q: $q) {
    id
    email
    person {
      id
      fullName    
    }
  }
}`;

export default {
  components: {
    UserWidget,
    SearchSelectInput,
  },
  props: {
    modelValue: {
      type: [Object, null],
      required: true,
    },
  },
  emits: ['update:model-value'],
  setup() {
    const { t } = useI18n();

    const q = useDebouncedRef('', 300, false);

    const searchQuery = useQuery({
      query: SEARCH_QUERY,
      requestPolicy: 'network-only',
      variables: computed(() => ({ q: q.value })),
      pause: () => q.value.length < 3,
    });

    const results = computed(() => searchQuery.data.value?.userSearch || []);

    return {
      t,
      loading: computed(() => !!(searchQuery.fetching.value || searchQuery.isPaused.value)),

      q,
      results,
    };
  },
};
</script>

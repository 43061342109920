import EventEmitter from 'eventemitter3';
import { ref } from 'vue';

export default class DropContext extends EventEmitter {

  constructor() {
    super();
    this.item = ref(null);
    this.dragging = ref(false);
    this.offset = ref(null);
    this.items = ref([]);
  }

  set(contentType, item, offset) {
    if (!this.dragging.value) {
      this.item.value = item;
      this.dragging.value = true;
      this.offset.value = offset;
    }
  }

  add(contentType, item) {
    if (!this.dragging.value) {
      this.items.value.push(item);
    }
  }

  remove(contentType, item) {
    if (!this.dragging.value) {
      const idx = this.items.value.indexOf(item);
      if (idx >= 0) {
        this.items.value.splice(idx, 1);
      }
    }
  }

  cancel() {
    if (this.dragging.value) {
      this.emit('drop', null, null);
      this.item.value = null;
      this.items.value = [];
      this.dragging.value = false;
      this.offset.value = null;
    }
  }

  drop(source) {
    if (this.dragging.value) {
      this.emit('drop', this.item.value, source, this.items.value);
      this.item.value = null;
      this.items.value = [];
      this.dragging.value = false;
      this.offset.value = null;
    }
  }
}

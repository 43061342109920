<template>
  <Pane
      class="file-drop-pane"
      :class="{'file-drop-pane--drop': dragging}"
      @drop.prevent="dropFile"
      @dragover.prevent="dragging=enabled"
      @dragleave="dragging=false">
    <slot />
  </Pane>
</template>
<i18n>
{
  "nl": {
  }
}
</i18n>
<script>
import {
  ref,
  toRefs,
} from 'vue';
import { useI18n } from 'vue-i18n';
import Pane from '../../panels/Pane.vue';

export default {
  components: { Pane },
  props: {
    accept: {
      type: Array,
      default: () => ['application/pdf'],
    },
    enabled: {
      type: Boolean,
      default: () => true,
    },
    maximumSize: {
      type: Number,
      default: () => 2000000,
    },
  },
  emits: ['drop'],
  setup(props, { emit }) {
    const dragging = ref(false);
    const { t } = useI18n();

    const {
      accept, maximumSize, enabled,
    } = toRefs(props);

    return {
      t,
      dragging,

      dropFile(ev) {
        const dropFiles = (ev.dataTransfer?.items) ? [...ev.dataTransfer.items]
          .filter((item) => item.kind === 'file').map((item) => item.getAsFile())
          : ev.target.files;
        const files = [...dropFiles].filter(
          (file) => accept.value.some((a) => file.type === a)
                && file.size <= maximumSize.value,
        );

        if (enabled.value) {
          emit('drop', files);
        }

        dragging.value = false;
      },
    };
  },
};
</script>
<style>

.file-drop-pane {
  &--drop {
    background-color: var(--color-primary-complement-background);
  }
}
</style>

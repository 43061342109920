<template>
  <article
      class="page">
    <header class="page__header">
      <div class="page__title">
        <slot name="header">
          <slot name="title">
            <PageTitle>
              {{ title }}
            </PageTitle>
          </slot>
        </slot>
      </div>
      <div
          v-if="!!$slots.actions"
          class="page__actions">
        <slot
            name="actions" />
      </div>
    </header>

    <div class="page__main">
      <slot name="main">
        <slot />
      </slot>
    </div>
  </article>
</template>
<script>
import { useI18n } from 'vue-i18n';
import PageTitle from './PageTitle.vue';

export default {
  components: {
    PageTitle,
  },
  props: {
    title: {
      type: String,
      default: () => null,
    },
  },
  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
};
</script>
<style>
.page {
  position: relative;
  display: grid;
  grid-template-rows: auto 1fr;

  &__header {
    display: grid;
    grid-auto-flow: column;
    gap: var(--dimension-small);

    padding: var(--dimension-page-padding);
    overflow: hidden;

    color: var(--color-default-foreground);

    background-color: var(--color-default-background);

    box-shadow: 0 var(--dimension-thick-line) var(--dimension-small) var(--color-background);
  }

  &__title {
    display: grid;
    grid-auto-flow: row;
    gap: var(--dimension-small);

    align-items: flex-end;
    align-self: flex-end;

  }

  &__actions {
    display: grid;
    grid-auto-flow: column;
    gap: var(--dimension-small);
    justify-content: flex-end;
    align-content: flex-end;
  }

  &__main {
    display: grid;
  }

}

@media screen and (max-width: 768px) {
  .page {
    &__header {
      grid-auto-flow: row;
    }

    &__actions {
      justify-content: flex-start;
      align-content: flex-start;
    }
  }
}

</style>

<template>
  <Header4 class="card-title">
    <div
        v-if="!!$slots.icon"
        class="card-title__icon">
      <slot name="icon" />
    </div>
    <HeaderTitle class="card-title__title">
      <slot>
        {{ title }}
      </slot>
    </HeaderTitle>
  </Header4>
</template>
<script>
import Header4 from '../typography/Header4.vue';
import HeaderTitle from '../typography/HeaderTitle.vue';

export default {
  components: { HeaderTitle, Header4 },
  props: {
    title: {
      type: String,
      default: () => null,
    },
  },
};
</script>
<style>
.card-title {
  display: flex;
  gap: var(--dimension-x-small);
  flex-direction: row;

  min-width: 0;

  &__title {
    flex: 1 1 0;
  }
}
</style>

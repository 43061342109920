<template>
  <div
      class="field-set">
    <div class="field-set__main">
      <Header4
          v-if="!!$slots.title"
          class="field-set__title">
        <slot
            name="title" />
      </Header4>
      <div class="field-set__fields">
        <slot />
      </div>
    </div>
    <div
        v-if="!!$slots.aside"
        class="field-set__aside">
      <slot name="aside" />
    </div>
  </div>
</template>

<script>
import Header4 from '../typography/Header4.vue';

export default {
  components: { Header4 },
};
</script>
<style>

.field-set {
  display: flex;
  flex-direction: row;

  margin: 0;

  column-gap: var(--dimension-small);

  &__fields {
    display: grid;
    grid-auto-flow: row;
    grid-gap: var(--dimension-small);
  }

  &__aside {
    display: grid;
  }

  &__main {
    flex: 1;
  }

  &__title {
    margin-bottom: var(--dimension-small);
  }
}
</style>

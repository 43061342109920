<template>
  <FlagIcon
      :title="t('hasPriority')"
      class="priority-icon" />
</template>
<i18n>
{
  "nl": {
    "hasPriority": "Heeft voorrang"
  },
  "en": {
    "hasPriority": "Has priority"
  }
}
</i18n>
<script>
import FlagIcon from '@carbon/icons-vue/es/warning-square--filled/16.js';
import { useI18n } from 'vue-i18n';

export default {
  components: { FlagIcon },
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
};
</script>
<style>
.priority-icon {
  height: 0.8em;

  cursor: help;

  vertical-align: baseline;
}
</style>

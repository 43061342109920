<template>
  <nav class="segmented-navigation">
    <ul class="segmented-navigation__tabs">
      <slot />
    </ul>
  </nav>
</template>
<style>
.segmented-navigation {
  display: contents;

  &__tabs {
    position: relative;
    display: grid;

    grid-auto-flow: column;
    gap: var(--dimension-medium);
  }

}
</style>

<template>
  <div class="spinner-number-input">
    <IconButton
        class="spinner-number-input__button"
        :reversed="true"
        :debounce-double-click="false"
        :enabled="!!(min === null || modelValue > min)"
        tabindex="-1"
        :icon-only="true"
        @click="decr">
      <template #icon>
        <MinusIcon />
      </template>
      {{ t('decrease') }}
    </IconButton>
    <NumberInput
        v-bind="$props"
        ref="input"
        @update:model-value="v => $emit('update:modelValue', v)" />
    <IconButton
        class="spinner-number-input__button"
        :enabled="!!(max === null || modelValue < max)"
        tabindex="-1"
        :debounce-double-click="false"
        :for="id"
        :icon-only="true"
        @click="incr">
      <template #icon>
        <PlusIcon />
      </template>
      {{ t('increase') }}
    </IconButton>
  </div>
</template>
<i18n>
{
  "nl": {
    "increase": "Verhoog",
    "decrease": "Verlaag"
  },
  "en": {
    "increase": "Increase",
    "decrease": "Decrease"
  }
}
</i18n>
<script>
import MinusIcon from '@carbon/icons-vue/es/subtract/24.js';
import PlusIcon from '@carbon/icons-vue/es/add/24.js';
import {
  ref,
  toRefs,
} from 'vue';
import { useI18n } from 'vue-i18n';
import NumberInput from './NumberInput.vue';
import IconButton from '../../buttons/IconButton.vue';

export default {
  components: {
    IconButton,
    NumberInput,
    PlusIcon,
    MinusIcon,
  },
  props: NumberInput.props,
  emits: NumberInput.emits,
  setup(props, { emit }) {
    // refs
    const {
      modelValue, min, max, step,
    } = toRefs(props);
    const input = ref(null);

    // uses
    const { t } = useI18n();

    return {
      input,

      t,

      incr() {
        const nv = (modelValue.value || modelValue.value === 0) ? modelValue.value + step.value : step.value;
        if ((max.value && nv <= max.value) || (max.value === 0 && nv <= 0) || !max.value) {
          emit('update:modelValue', nv);
        }
        input.value?.$el.focus();
      },
      decr() {
        const nv = (modelValue.value || modelValue.value === 0) ? modelValue.value - step.value : 0;
        if ((min.value && nv >= min.value) || (min.value === 0 && nv >= 0) || !min.value) {
          emit('update:modelValue', nv);
          input.value?.$el.focus();
        }
        input.value?.$el.focus();
      },
    };
  },
};
</script>
<style>
.spinner-number-input {
  display: grid;
  grid-template-columns: 3rem 1fr 3rem;

  &__button {
    margin: var(--dimension-small);
  }
}
</style>

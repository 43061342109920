<template>
  <li
      class="horizontal-tab-panel"
      :class="{
          'horizontal-tab-panel--active': isActive,
          'horizontal-tab-panel--exact': isActive,
        }"
      @click="$emit('update:model-value', id)">
    <div
        v-if="!!$slots.icon"
        class="horizontal-tab-panel__icon">
      <slot name="icon" />
    </div>
    <slot>
      <span class="horizontal-tab-panel__label">
        <slot name="label" />
      </span>
    </slot>
    <slot
        v-if="false"
        name="content" />
  </li>
</template>
<script>

import {
  computed,
  inject,
  onMounted,
  toRefs,
} from 'vue';

export default {
  props: {
    id: {
      type: String,
      default: () => null,
      required: true,
    },
    modelValue: {
      type: String,
      default: () => null,
    },
  },
  emits: ['update:model-value'],
  setup(props, { slots }) {
    const { id, modelValue } = toRefs(props);
    const controller = inject('tabController');

    const register = () => {
      controller.register({
        id: id.value,
        slots,
      });
    };

    onMounted(register);

    return {
      isActive: computed(() => modelValue.value === id.value),
    };
  },
};
</script>
<style>
.horizontal-tab-panel {
  @media (any-hover: hover) {
    &:hover {
      background-color: color-mix(in srgb, var(--color-background), var(--color-default-foreground) 10%);
      border-bottom-color: rgba(var(--rgb-default-foreground), .5);
    }
  }
  display: flex;
  gap: var(--dimension-small);
  justify-content: flex-start;
  align-items: center;

  padding: var(--dimension-x-small) var(--dimension-small);

  color: inherit;
  background-color: color-mix(in srgb, var(--color-background), var(--color-default-foreground) 2%);
  border-right: 0;
  border-bottom: var(--dimension-x-small) solid rgba(var(--rgb-default-foreground), .1);
  border-left: 0;
  cursor: pointer;

  text-decoration: none;

  transition: border-bottom-color var(--animation-default-duration) linear, background-color var(--animation-default-duration);

  &__label {
    display: block;
    flex: 1;

    padding: var(--dimension-small) 0;
  }

  &--exact {
    border-bottom-color: var(--color-primary-background);

    font-weight: 500;
  }
}
</style>

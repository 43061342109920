<template>
  <DialogActionPane class="paged-table">
    <header v-if="q || rows?.length > 0">
      <div class="paged-table__search">
        <TextInput
            ref="searchEl"
            v-model="internalQ"
            :placeholder="searchPlaceholder"
            @update:model-value="firstPage" />
        <slot />
        <SearchIcon class="paged-table__search-icon" />
      </div>
    </header>
    <InsetPane v-if="rows?.length === 0">
      <slot
          v-if="q"
          name="nothingFound"
          :q="q" />
      <slot
          v-else
          name="empty" />
    </InsetPane>
    <LoaderPane
        :loading="loading">
      <TableContainer v-if="rows?.length > 0">
        <TableContent :rows="rows">
          <template #header>
            <slot name="header" />
          </template>
          <template #row="props">
            <slot
                name="row"
                v-bind="props" />
          </template>

          <template #empty>
            <TableRow>
              <TableCell
                  :columns="columns" />
            </TableRow>
          </template>
        </TableContent>
      </TableContainer>
    </LoaderPane>

    <template
        v-if="loading || rows?.length > 0"
        #actions>
      <IconButton
          v-if="page?.hasNextPage || page?.hasPreviousPage"
          type="action"
          :enabled="!loading && page?.hasPreviousPage"
          :reversed="true"
          @click="firstPage()">
        <template #icon>
          <PageFirstIcon />
        </template>
        {{ t('firstPage') }}
      </IconButton>
      <IconButton
          :enabled="!loading && page?.hasPreviousPage"
          :reversed="true"
          @click="previous(page.startCursor)">
        <template #icon>
          <PagePreviousIcon />
        </template>
        {{ t('previous', { count: pageSize }) }}
      </IconButton>
      <slot name="actions" />
    </template>

    <template
        v-if="loading || rows?.length > 0"
        #buttons>
      <IconButton
          :enabled="!loading && page?.hasNextPage"
          @click="next(page.endCursor)">
        <template #icon>
          <PageNextIcon />
        </template>
        {{ t('next', { count: pageSize }) }}
      </IconButton>
      <IconButton
          v-if="!loading && page?.hasNextPage || page?.hasPreviousPage"
          type="action"
          :enabled="page?.hasNextPage"
          @click="lastPage()">
        <template #icon>
          <PageLastIcon />
        </template>
        {{ t('lastPage') }}
      </IconButton>
    </template>
  </DialogActionPane>
</template>
<i18n>
{
  "nl": {
    "lastPage": "Naar einde",
    "firstPage": "Naar begin",
    "next": "Volgende {count}",
    "previous": "Vorige {count}"
  },
  "en": {
    "lastPage": "First page",
    "firstPage": "Last page",
    "next": "Next {count}",
    "previous": "Previous {count}"
  }
}
</i18n>
<script>

import { useI18n } from 'vue-i18n';
import PageLastIcon from '@carbon/icons-vue/es/page--last/24.js';
import PageFirstIcon from '@carbon/icons-vue/es/page--first/24.js';
import PageNextIcon from '@carbon/icons-vue/es/chevron--right/24.js';
import PagePreviousIcon from '@carbon/icons-vue/es/chevron--left/24.js';
import SearchIcon from '@carbon/icons-vue/es/search/24.js';
import {
  computed,
  watch,
} from 'vue';
import TableContainer from './TableContainer.vue';
import TableContent from './TableContent.vue';
import TableCell from './TableCell.vue';
import IconButton from '../buttons/IconButton.vue';
import DialogActionPane from '../panels/DialogActionPane.vue';
import TableRow from './TableRow.vue';
import TextInput from '../widgets/forms/TextInput.vue';
import { useDebouncedRef } from '../../utils/refs.js';
import InsetPane from '../panels/InsetPane.vue';
import LoaderPane from '../loading/LoaderPane.vue';

export default {
  components: {
    LoaderPane,
    InsetPane,
    SearchIcon,
    TextInput,
    TableRow,
    DialogActionPane,
    IconButton,
    TableCell,
    TableContent,
    TableContainer,
    PageLastIcon,
    PageFirstIcon,
    PagePreviousIcon,
    PageNextIcon,
  },
  props: {
    ...TableContent.props,
    loading: {
      type: Boolean,
      default: () => 25,
    },
    pageSize: {
      type: Number,
      default: () => 25,
    },
    page: {
      type: Object,
      default: () => null,
    },
    searchPlaceholder: {
      type: String,
      default: () => null,
    },
    before: {
      type: String,
      default: () => null,
    },
    after: {
      type: String,
      default: () => null,
    },
    q: {
      type: String,
      default: () => null,
    },
  },
  emits: ['update:before', 'update:after', 'update:q'],
  setup(props, { emit }) {
    // refs
    const q = useDebouncedRef('', 300, false);

    // uses
    const { t } = useI18n();

    // methods
    const next = (c) => {
      emit('update:before', null);
      emit('update:after', c);
    };
    const previous = (c) => {
      emit('update:before', c);
      emit('update:after', null);
    };

    const firstPage = () => {
      emit('update:before', null);
      emit('update:after', null);
    };
    const lastPage = () => {
      emit('update:after', null);
      emit('update:before', '');
    };
    const internalQ = computed({
      get() {
        return q.value;
      },
      set(v) {
        q.value = v;
      },
    });

    watch(q, (nq) => emit('update:q', nq));

    return {
      t,

      internalQ,

      next,
      previous,
      firstPage,
      lastPage,
    };
  },
};

</script>
<style>
.paged-table {
  &__search {
    display: flex;
    flex-direction: row;
    align-items: center;

    color: rgba(var(--rgb-black), .25);
    background-color: rgba(var(--rgb-white), .5);

    &-icon {
      margin: 0 var(--dimension-x-small);
    }
  }

}
</style>

<template>
  <IconLabel class="person-label">
    <template #icon>
      <PersonIcon />
    </template>
    <span>
        {{ person.fullName }}
    </span>
  </IconLabel>
</template>
<script>
import PersonIcon from '@carbon/icons-vue/es/user/16.js';
import IconLabel from './IconLabel.vue';

export default {
  components: {
    IconLabel,
    PersonIcon,
  },
  props: {
    person: {
      type: Object,
      required: true,
    },
  },
};
</script>

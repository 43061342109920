<template>
  <List
      class="select-multiple-list"
      :items="items">
    <template #default="{item}">
      <CheckBox
          :model-value="indexOf(item) !== -1"
          :value="item[idField]"
          :name="name"
          @update:model-value="v => select(item, v)">
        <template #content="{for: forId}">
          <slot
              name="content"
              :for-id="forId"
              :item="item" />
        </template>
        <slot
            :item="item"
            name="label">
          {{ item[labelField] }}
        </slot>
      </CheckBox>
    </template>
  </List>
</template>
<script>
import { toRefs } from 'vue';
import List from '../list/ListContainer.vue';
import CheckBox from '../widgets/forms/CheckBox.vue';

export default {
  components: { CheckBox, List },
  props: {
    ...List.props,
    labelField: {
      type: String,
      default: () => 'label',
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      default: () => null,
      required: true,
    },
  },
  emits: ['update:model-value'],

  setup(props, { emit }) {
    const { modelValue, idField, items } = toRefs(props);

    const indexOf = (item) => modelValue.value
      ?.map((i) => i[idField.value])
      .indexOf(item[idField.value]);

    return {
      indexOf,
      select(item, value) {
        const idx = indexOf(item);
        const ids = modelValue.value?.map((i) => i[idField.value]);
        if (value && modelValue.value && idx === -1) {
          ids.push(item[idField.value]);
        } else if (!value && idx !== -1) {
          ids.splice(idx, 1);
        }
        emit('update:model-value', items.value.filter((i) => ids.indexOf(i[idField.value]) !== -1));
      },
    };
  },
};
</script>

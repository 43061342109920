<script>
import {
  h,
  toRefs,
} from 'vue';

export default {
  props: {
    tag: {
      type: String,
      default: () => 'p',
    },
    type: {
      type: String,
      default: () => null,
      validator(value) {
        // The value must match one of these strings
        return [null, 'intro'].includes(value);
      },
    },
  },
  setup(props, { slots }) {
    const { tag, type } = toRefs(props);
    return () => h(
      tag.value,
      {
        class: ['paragraph'].concat(((type.value) ? [`paragraph--${type.value}`] : [])),
      },
      (slots.default) ? slots.default() : '',
    );
  },
};
</script>
<!--suppress CssUnusedSymbol -->
<style>

.paragraph {
  max-width: 50ch;

  font-family: var(--font-sans-serif);
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.3;

  hyphens: auto;

  user-select: text;

  &--intro {
    max-width: 50ch;
    margin-bottom: 1em;
  }
}
</style>

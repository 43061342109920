<template>
  <section class="navigation-tabs-group">
    <header class="navigation-tabs-group__header">
      <Header4>
        {{ title }}
      </Header4>
    </header>
    <slot />
  </section>
</template>
<script>

import Header4 from '../typography/Header4.vue';

export default {
  components: { Header4 },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
<style>
.navigation-tabs-group {
  &__header {
    padding: var(--dimension-x-small);
  }
}
</style>

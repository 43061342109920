<template>
  <DetailPanel
      class="tabs-container"
      :top="top"
      :width="width">
    <template #default>
      <div class="tabs-container__container">
        <slot name="header" />
        <ul class="tabs-container__tabs">
          <slot />
        </ul>
        <slot name="footer" />
      </div>
    </template>
    <template
        #detail>
      <component
          :is="selected"
          v-if="selected" />
      <slot
          v-else
          name="empty" />
    </template>
  </DetailPanel>
</template>
<script>
import {
  computed,
  provide,
  ref,
  toRefs,
} from 'vue';
import DetailPanel from '../../panels/DetailPanel.vue';

export default {
  components: { DetailPanel },
  props: {
    ...DetailPanel.props,
    modelValue: {
      type: String,
      default: () => null,
    },
  },
  emits: ['update:model-value'],
  setup(props, { emit }) {
    const { modelValue } = toRefs(props);
    const tabs = ref([]);
    provide('tabController', {
      select(tab) {
        if (modelValue.value !== tab.id) {
          emit('update:model-value', tab.id);
        }
      },
      register(tab) {
        const idx = tabs.value.map((t) => t.id).indexOf(tab.id);
        if (idx !== -1) {
          tabs.value.splice(idx, 1, tab);
        } else {
          tabs.value.push(tab);
        }
      },
    });

    return {
      tabs,
      selected: computed(() => (modelValue.value
        ? tabs.value.find(
          (t) => t.id === modelValue.value,
        )?.slots.content || null
        : null)),
    };
  },
};
</script>
<style>
.tabs-container {

  &__container {
    display: flex;
    flex: 1;
    flex-direction: column;

    min-height: 0;

    background-color: var(--color-background);
  }

  &__tabs {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-content: flex-start;

    min-height: 0;

    > * {
      border-bottom: 1px solid var(--color-default-background);
    }
  }
}
</style>

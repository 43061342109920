<template>
  <div class="card-stack">
    <slot name="header" />
    <ul>
      <transition-group
        v-if="!loading"
        name="animation-cross-fade">
        <template v-for="(card, idx) in cards">
          <slot
            name="card"
            :card="card">
            <li
              :key="(card && idField) ? card[idField] : `index:${idx}`"
              class="card-stack__card">
              <slot :card="card" />
            </li>
          </slot>
        </template>
      </transition-group>
      <template
        v-else>
        <li
          v-for="(loadingCard, idx) in loadingCards"
          :key="`index:${idx}`"
          class="card-stack__card">
          <slot
            :card="loadingCard"
            :idx="idx"
            name="loading" />
        </li>
      </template>
    </ul>
    <slot name="footer" />
  </div>
</template>
<script>

export default {
  props: {
    idField: {
      type: String,
      default: () => 'id',
    },
    cards: {
      type: Array,
      default: () => [],
      required: true,
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    loadingCards: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style>
.card-stack {
  display: grid;
  grid-auto-flow: row;
  gap: var(--dimension-medium);

  align-content: flex-start;

  > ul {
    display: contents;
  }

  &__card {
    display: grid;
  }
}
</style>

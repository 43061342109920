import gql from 'graphql-tag';

export function getGroupGradeName(gt, groupGrade) {
  const name = gt(`grades.${groupGrade.grade.slug}`);
  if (groupGrade.grade.sport === 'AGILITY' && groupGrade.categories.length < 3) {
    return `${name} ${groupGrade.categories.map((c) => c.size[0]).join('+')}`;
  }
  return name;
}

export const GROUP_GRADE_NAME_FRAGMENT = gql`
  fragment GroupGradeNameFields on GroupGrade {
    grade {
      id
      slug
      sport
    }
    categories {
      id
      size
    }
  }
`;
export default getGroupGradeName;

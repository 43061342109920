<template>
  <TextInput
      v-model="inputValue"
      :read-only="readOnly"
      @focus="focus"
      @blur="blur"
      @update:model-value="handleInput" />
</template>
<script>
import {
  computed,
  ref,
  toRefs,
} from 'vue';
import TextInput from './TextInput.vue';

const currencyFormatter = new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' });
const numberFormatter = new Intl.NumberFormat('nl-NL', {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
  useGrouping: false,
});

export default {
  components: { TextInput },
  props: {
    modelValue: {
      type: Number,
      default: () => null,
    },
    readOnly: {
      type: Boolean,
      default: () => false,
    },
  },
  emits: ['update:model-value'],
  setup(props, { emit }) {
    const { modelValue } = toRefs(props);

    const inputValue = ref((modelValue.value !== null)
      ? currencyFormatter.format(modelValue.value)
      : '');

    const formattedCurrencyValue = computed(() => ((modelValue.value !== null)
      ? currencyFormatter.format(modelValue.value) : ''));
    const formattedNumberValue = computed(() => ((modelValue.value !== null)
      ? numberFormatter.format(modelValue.value) : ''));
    return {
      inputValue,
      handleInput(value) {
        const basicValue = value.replace(',', '.');
        const numberValue = Number.parseFloat(basicValue);

        if (Number.isNaN(numberValue)) {
          emit('update:model-value', null);
        } else {
          emit('update:model-value', numberValue);
        }
      },
      focus() {
        inputValue.value = formattedNumberValue.value;
      },
      blur() {
        inputValue.value = formattedCurrencyValue.value;
      },

    };
  },

};
</script>

<template>
  <article class="page-error-message">
    <section class="page-error-message__message">
      <slot>
        {{ error }}
      </slot>
    </section>
  </article>
</template>
<script>
export default {
  props: {
    error: {
      type: String,
      default: () => '',
    },
  },
};
</script>
<style>
.page-error-message {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
</style>

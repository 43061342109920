<template>
  <Header2 class="panel-title">
    <HeaderTitle>
      <slot />
    </HeaderTitle>
  </Header2>
</template>
<script>
import Header2 from '../typography/Header2.vue';
import HeaderTitle from '../typography/HeaderTitle.vue';

export default {
  components: { HeaderTitle, Header2 },
};
</script>
<style>

.panel-title {
  align-self: center;

  padding: var(--dimension-small) var(--dimension-medium);

  color: var(--color-secondary-header);
}
</style>

<template>
  <div
      class="pager-page">
    <slot />
  </div>
</template>
<script>

export default {
  props: {
    id: {
      type: String,
      default: () => null,
      required: true,
    },
  },
};
</script>
<style>
.pager-page {
  display: grid;
  flex-shrink: 0;
  flex-basis: 100%;
}
</style>

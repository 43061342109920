<template>
  <nav class="navigation-tabs">
    <slot name="header" />
    <ul class="navigation-tabs__tabs">
      <transition-group
          name="animation-cross-fade">
        <slot />
      </transition-group>
    </ul>
    <slot name="footer" />
  </nav>
</template>
<style>

:root {
  --navigation-tabs-background-color: var(--color-background);
}
.navigation-tabs {
  display: flex;
  flex-direction: column;

  background-color: var(--navigation-tabs-background-color);

  &__tabs {
    display: grid;
    grid-auto-flow: row;
    flex: 1;
    align-content: flex-start;

    > * {
      border-bottom: 1px solid var(--color-default-background);
    }
  }
}
</style>

<template>
  <div
    ref="root"
    :class="{
      'popover--visible': modelValue,
      'popover--hover': showOnHover,
      'popover--windowed': windowed,
    }"
    :tabindex="(closeOnOutsideClick)?'0': undefined"
    class="popover"
    @focusout="blur">
    <div class="popover__slot">
      <slot />
    </div>
    <div
      class="popover__content"
      :aria-hidden="`${!modelValue}`"
      role="tooltip"
      @click.stop="">
      <TextLabel
        tag="div"
        class="popover__container">
        <slot name="popover" />
      </TextLabel>
    </div>
  </div>
</template>
<script>

import {
  inject,
  ref,
  toRefs,
} from 'vue';
import TextLabel from '../labels/TextLabel.vue';

export default {
  components: { TextLabel },
  props: {
    modelValue: {
      type: Boolean,
      default: () => false,
    },
    closeOnOutsideClick: {
      type: Boolean,
      default: () => false,
    },
    showOnHover: {
      type: Boolean,
      default: () => false,
    },
  },
  emits: ['update:model-value'],
  setup(props, { emit }) {
    const { modelValue, closeOnOutsideClick } = toRefs(props);
    const root = ref(null);
    const windowed = inject('windowed');

    return {
      root,
      windowed,
      blur(e) {
        if (modelValue.value && closeOnOutsideClick.value
          && (!e.relatedTarget || !root.value.contains(e.relatedTarget))) {
          e.preventDefault();
          emit('update:model-value', false);
        }
      },
    };
  },
};
</script>
<style>
.popover {
  position: relative;

  &__content {
    position: absolute;
    right: 0;
    left: 0;
    display: none;

    padding: var(--dimension-x-small);

    background-color: var(--color-background);
    box-shadow: 0 .25rem 1rem -.125rem rgba(var(--rgb-black), .1);
  }

  &--visible &__content {
    display: block;
  }
}
</style>

<template>
  <TabbablePanel
      class="sub-tabbable-panel"
      :divider="false">
    <template
        v-if="title"
        #title>
      <Header4 class="sub-tabbable-panel__title">
        {{ title }}
      </Header4>
    </template>

    <slot />
  </TabbablePanel>
</template>
<script>
import Header4 from '../typography/Header4.vue';
import TabbablePanel from './TabbablePanel.vue';

export default {
  components: {
    TabbablePanel,
    Header4,
  },
  props: {
    title: {
      type: String,
      default: () => null,
    },
  },
};
</script>
<template>
  <FormFieldReadOnlyValue class="form-field-read-only-text-value">
    <ContentBlock class="form-field-read-only-text-value__content">
      <slot/>
    </ContentBlock>
  </FormFieldReadOnlyValue>
</template>
<script>
import FormFieldReadOnlyValue from './FormFieldReadOnlyValue.vue';
import ContentBlock from '../content/ContentBlock.vue';

export default {
  components: { ContentBlock, FormFieldReadOnlyValue },
  setup() {
    return {};
  },
};
</script>
<style>
.form-field-read-only-text-value {
  &__content {
    padding: var(--dimension-small);
  }
}
</style>

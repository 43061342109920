<template>
  <article
      v-if="error"
      class="navigation-guard">
    <template v-if="notFound === true">
      <slot name="not-found">
        <Error :error="t('notFound')" />
      </slot>
    </template>
    <template v-else-if="permission === false">
      <slot name="permission-denied">
        <Error :error="t('permissionDenied')" />
      </slot>
    </template>
  </article>
  <div
      v-else
      class="navigation-guard">
    <slot />
  </div>
</template>
<i18n>
{
  "nl": {
    "permissionDenied": "Geen toegang",
    "notFound": "Niet gevonden"
  },
  "en": {
    "permissionDenied": "Permission denied",
    "notFound": "Not found"
  }
}
</i18n>
<script>
import {
  computed,
  toRefs,
  useSSRContext,
} from 'vue';
import { useI18n } from 'vue-i18n';
import Error from '../pages/PageErrorMessage.vue';

export default {
  components: { Error },
  props: {
    permission: {
      type: Boolean,
      default: () => true,
    },
    notFound: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props) {
    const { permission, notFound } = toRefs(props);
    const { t } = useI18n();

    if (import.meta.env.SSR && notFound.value === true) {
      // We do not check permission since the SSR server does not have the user's session.
      const ctx = useSSRContext();
      ctx.responseCode = 404;
    }

    return {
      t,
      error: computed(() => permission.value === false || notFound.value === true),
    };
  },
};
</script>
<style>
.navigation-guard {
  display: grid;
  flex: 1;
}
</style>

<template>
  <div
      class="date-block"
      :class="{
      'date-block--inline': inline,
      'date-block--past': inPast,
      'date-block--today': isToday,
    }">
    <span class="date-block__date">
      <span
          v-if="showDay"
          class="date-block__day">
        <slot
            name="day"
            :date="day"
            :day="(long) ? longWeekDay : weekDay">
        {{ (long) ? longWeekDay : weekDay }}
        </slot>
      </span>
      <template v-if="showDate">
        <slot
            name="date"
            :date="day">
        {{ day }}
        </slot>
      </template>
    </span>
    <span
        v-if="showMonth || showYear"
        class="date-block__full">
      <template v-if="showMonth && long">{{ month }}</template>
      <template v-else-if="showMonth">{{ shortMonth }}</template>
      <template v-if="showYear">{{ year }}</template>
    </span>
  </div>
</template>
<script>
import { useI18n } from 'vue-i18n';
import {
  computed,
  toRefs,
} from 'vue';
import {
  isBefore,
  isEqual,
  startOfToday,
} from 'date-fns';

export default {
  props: {
    date: {
      type: Date,
      required: true,
    },
    showDay: {
      type: Boolean,
      default: () => true,
    },
    showDate: {
      type: Boolean,
      default: () => true,
    },
    showMonth: {
      type: Boolean,
      default: () => true,
    },
    showYear: {
      type: Boolean,
      default: () => false,
    },
    inline: {
      type: Boolean,
      default: () => false,
    },
    long: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props) {
    const { date } = toRefs(props);
    const { locale } = useI18n();
    return {
      inPast: computed(() => isBefore(date.value, startOfToday())),
      isToday: computed(() => isEqual(date.value, startOfToday())),
      shortMonth: computed(() => new Intl.DateTimeFormat(locale.value, {
        month: 'short',
      }).format(date.value).replace('.', '')),
      month: computed(() => new Intl.DateTimeFormat(locale.value, {
        month: 'long',
      }).format(date.value)),
      weekDay: computed(() => new Intl.DateTimeFormat(locale.value, {
        weekday: 'short',
      }).format(date.value)),
      longWeekDay: computed(() => new Intl.DateTimeFormat(locale.value, {
        weekday: 'long',
      }).format(date.value)),
      day: computed(() => new Intl.DateTimeFormat(locale.value, {
        day: '2-digit',
      }).format(date.value)),
      year: computed(() => new Intl.DateTimeFormat(locale.value, {
        year: 'numeric',
      }).format(date.value)),
    };
  },
};
</script>
<style>
:root {
  --date-block-padding: var(--dimension-x-small);
  --date-block-font-size: .75rem;
  --date-block-color: var(--color-primary-foreground);
  --date-block-background: var(--color-primary-background);
  --date-block-complement-color: var(--color-primary-complement-foreground);
  --date-block-complement-background: var(--color-primary-complement-background);
}

.date-block {
  box-sizing: content-box;

  color: var(--date-block-color);
  background-color: var(--date-block-background);
  list-style: none;

  font-family: var(--font-sans-serif);
  font-size: var(--date-block-font-size);

  font-weight: 400;
  font-style: normal;
  text-align: center;

  &--past {
    --date-block-background: var(--color-secondary-background);
    --date-block-color: var(--color-secondary-foreground);
    --date-block-complement-background: var(--color-secondary-complement-background);
    --date-block-complement-color: var(--color-secondary-complement-foreground);
  }

  &--today {
    --date-block-background: var(--color-secondary-complement-background);
    --date-block-color: var(--color-secondary-complement-foreground);
    --date-block-complement-background: var(--color-secondary-complement-background);
    --date-block-complement-color: var(--color-secondary-complement-foreground);
  }

  &--inline {
    display: inline-flex;
    flex-direction: row;
  }

  &__date {
    display: block;

    padding: var(--date-block-padding);

    font-variant-numeric: tabular-nums;
  }

  &--inline &__date {
    display: flex;
    flex-direction: row;
  }

  &__day {
    display: block;

    font-weight: 600;
    line-height: 1em;
    text-transform: uppercase;
    font-variant-numeric: tabular-nums;
  }

  &--inline &__day {
    padding-right: var(--date-block-padding);
  }

  &__full {
    display: block;

    padding: var(--date-block-padding);

    color: var(--date-block-complement-color);
    background-color: var(--date-block-complement-background);

    font-size: 1em;
    font-weight: 400;
    text-transform: uppercase;
  }
}
</style>
